import React from "react";
import cx from "classnames";
import Text from "components/generic/Text/Text";
import styles from "./ScoreRow.module.scss";
import { Dimensions, SubDimensions } from "types/dimensions";
import { useTranslation } from "react-i18next";
import {
  getSubDimensionTranslationKey,
  getDimensionTranslationKey,
} from "hooks/useReport";

interface ScoreRowProps {
  benchmarkAvailable: boolean;
  dimension: Dimensions;
  qcode?: SubDimensions;
  benchmark: number;
  className?: string;
  score: number;
}

const ScoreRow = ({
  benchmarkAvailable,
  qcode,
  dimension,
  benchmark,
  className,
  score,
}: ScoreRowProps) => {
  const { t } = useTranslation();
  return (
    <div className={cx(styles.score_row, className)}>
      <Text weight="bold" className={styles.score_row_title}>
        {qcode
          ? t(`subDimensions.${getSubDimensionTranslationKey(qcode)}`).join(" ")
          : t(`dimensions.${getDimensionTranslationKey(dimension)}`)}
      </Text>
      <div className={styles.score_scores}>
        <div className={styles.score_scores_column}>
          <Text>{t("dimensions.your_score")}:</Text>
          <Text
            className={cx({
              [styles.positive]: score >= benchmark && benchmarkAvailable,
              [styles.negative]: score < benchmark && benchmarkAvailable,
            })}
            weight="bold"
          >
            {score}
          </Text>
        </div>
        {benchmarkAvailable && (
          <div className={styles.score_scores_column}>
            <Text>{t("dimensions.benchmark")}:</Text>
            <Text
              className={cx({
                [styles.positive]: benchmark > score,
                [styles.negative]: benchmark <= score,
              })}
              weight="bold"
            >
              {benchmark}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default ScoreRow;
