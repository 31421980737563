import React from "react";

interface CreateContextOptions {
  name: string;
}

type CreateContextReturn<T> = [React.Provider<T>, () => T, React.Context<T>];

function createContext<ContextType>({ name }: CreateContextOptions) {
  const Ctx = React.createContext<ContextType | undefined>(undefined);

  Ctx.displayName = name;

  const useCtx = () => React.useContext(Ctx);

  return [Ctx.Provider, useCtx, Ctx] as CreateContextReturn<ContextType>;
}

export default createContext;
