import React, { HTMLProps } from "react";
import Icon, { IconKeys } from "assets/icons/Icon";
import Spinner from "../Spinner/Spinner";
import styles from "./Button.module.scss";
import cx from "classnames";

/**
 * @returns A button component, with various variants available. Pass an icon to the component using either iconLeft or iconRight prop.
 *
 */

export type ButtonVariants = "filled" | "outline" | "white" | "ghost";

export type ButtonSizes = "md" | "sm" | "xs";

interface ButtonProps extends Omit<HTMLProps<HTMLButtonElement>, "size"> {
  /** Content for the button */
  children: React.ReactNode | React.ReactNode[];
  /** Set the URL of the link. */
  href?: string;
  /**
   * String to display icon on the left
   */
  iconLeft?: IconKeys;
  /**
   * String to display icon on the right
   */
  iconRight?: IconKeys;
  /**
   * Toggles disabled state on the button
   * @default false;
   */
  isDisabled?: boolean;
  /**
   * Toggles loading state on the button
   * @default false
   */
  isLoading?: boolean;
  /**
   * Change the intent of the button
   * @default normal
   */
  intent?: "normal" | "danger";
  /**
   * Change the text displayed when isLoading is true
   * @default "Loading..."
   */
  loadingText?: string;
  /**
   * Function to run on click
   */
  onClick?: () => void;
  /**
   * Change the size of the button
   * @default md
   */
  size?: "md" | "sm" | "xs";
  /**
   * Change the type of button to render
   * @default button
   */
  type?: "button" | "submit" | "reset";
  /**
   * Change the fill type of the button
   * @default filled
   */
  variant?: ButtonVariants;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, refProp) => {
    const {
      children,
      iconLeft,
      iconRight,
      isDisabled,
      isLoading,
      className,
      variant = "filled",
      size = "md",
      loadingText = "Loading...",
      ...rest
    } = props;

    const classNames = cx(
      styles.button,
      {
        [styles.filled]: variant === "filled",
        [styles.outline]: variant === "outline",
        [styles.ghost]: variant === "ghost",
        [styles.white]: variant === "white",
        [styles.lg]: size === "md",
        [styles.md]: size === "sm",
        [styles.sm]: size === "xs",
      },
      className
    );

    return (
      <button
        disabled={isDisabled || isLoading}
        data-loading={isLoading ? true : undefined}
        ref={refProp}
        className={classNames}
        {...rest}
      >
        {iconLeft && !isLoading && (
          <Icon icon={iconLeft} className={styles.iconLeft} />
        )}

        {isLoading && <Spinner size="xs" />}

        {isLoading
          ? (loadingText && (
              <span className={styles.loading}>{loadingText}</span>
            )) || <span className={styles.hiddenLoading}>Loading...</span>
          : children}

        {iconRight && !isLoading && (
          <Icon icon={iconRight} className={styles.iconRight} />
        )}
      </button>
    );
  }
);

export default Button;
