import { ChartOptions } from "chart.js";

export type LineOptions = ChartOptions<"line">;

const options: LineOptions = {
  animation: false,
  indexAxis: "y",
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  scales: {
    x: {
      beginAtZero: false,
      grid: {
        drawBorder: false,
      },
      max: 5,
      min: 1,
      offset: true,
      ticks: {
        callback(label: string | number): string | null {
          switch (label) {
            case 1:
              return "Strongly agree";
            case 2:
              return "Agree";
            case 3:
              return "Not sure";
            case 4:
              return "Disagree";
            case 5:
              return "Strongly disagree";
            default:
              return null;
          }
        },
        color: "#666",
        font: {
          family: "GT Walsheim Pro",
          size: 12,
        },
        padding: 24,
        stepSize: 1,
      },
    },
    y: {
      stacked: false,
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        color: "rgba(35, 20, 56, 1)",
        display: false,
        font: {
          family: "GT Walsheim Pro",
          lineHeight: 1.5,
          weight: "regular",
        },
      },
    },
  },
};

export default options;
