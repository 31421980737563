import cx from "classnames";
import React from "react";
import { Color, LegendItemType } from "hooks/useReport";
import Text from "components/generic/Text/Text";
import Skeleton from "components/Skeleton";
import styles from "./Legend.module.scss";

interface LegendProps {
  list: LegendItemType[];
  isLoading: boolean;
}

interface LegendItemProps extends Partial<LegendItemType> {
  children?: React.ReactNode;
}

const Legend = ({ list, isLoading }: LegendProps) => (
  <ul className={styles.legend}>
    {isLoading ? (
      <LegendItem />
    ) : (
      list?.map((item) => (
        <LegendItem key={item.label} style={{ ...item.style }}>
          {item.label}
        </LegendItem>
      ))
    )}
  </ul>
);

export default Legend;

const LegendItem = ({ children, color = "teal", ...rest }: LegendItemProps) => (
  <Text variant="caption" as="li" className={styles.legend_item}>
    {children ? (
      <LegendSquare color={color} {...rest} />
    ) : (
      <Skeleton height={20} width={120} />
    )}
    {children}
  </Text>
);

const LegendSquare = ({ color, ...rest }: { color: Color }) => (
  <span
    {...rest}
    className={cx(styles.legend_square, {
      [styles.teal]: color === "teal",
      [styles.red]: color === "red",
      [styles.orange]: color === "orange",
      [styles.softGray]: color === "softGray",
      [styles.purple]: color === "purple",
      [styles.green]: color === "green",
    })}
  />
);
