import React from "react";
import cx from "classnames";
import styles from "./ControlWrapper.module.scss";

interface ControlWrapperProps {
  children: React.ReactNode;
  className?: string;
}

/** Provides consistent container for report controls */
function ControlWrapper({ children, className, ...rest }: ControlWrapperProps) {
  return (
    <div className={cx(styles.wrapper, className)} {...rest}>
      {children}
    </div>
  );
}

export default ControlWrapper;
