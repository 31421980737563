import React, { useState, useRef } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { MdCheckCircle } from "react-icons/md";
import Text from "../generic/Text/Text";
import useOnClickOutside from "../generic/hooks/useOnClickOutside";
import Dropdown from "../ReportControls/Dropdown/index";
import styles from "./LanguageSelector.module.scss";
import usePortal from "../generic/hooks/usePortal";
import { LanguageCodes, LanguageLabels } from "i18n/config";

interface Languages {
  code: LanguageCodes;
  label: LanguageLabels;
}

const DEFAULT_LANGUAGES: Languages[] = [
  {
    code: "en",
    label: "English",
  },
  {
    code: "ja",
    label: "Japanese",
  },
];

const LanguageSelector = ({ languages = DEFAULT_LANGUAGES }) => {
  const divRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { i18n } = useTranslation();

  const changeLanguage = (lng: LanguageCodes) => {
    if (lng === i18n.language) return null;
    i18n.changeLanguage(lng);

    if (lng === "ja") {
      document.documentElement.style.setProperty(
        "--font-family",
        "Noto Sans JP"
      );
    } else {
      document.documentElement.style.setProperty(
        "--font-family",
        '"GT-Walsheim Pro", Arial, Helvetica, sans-serif'
      );
    }

    return true;
  };

  const LanguageContainer = usePortal();

  useOnClickOutside(divRef, () => setIsOpen(false));
  return (
    <LanguageContainer>
      <div className={styles.selector}>
        <div ref={divRef} className={styles.selector_wrapper}>
          {isOpen && (
            <Dropdown className={styles.selector_dropdown}>
              {languages?.map((lang) => (
                <LanguageRow
                  activeLanguage={i18n.language as LanguageCodes}
                  onClick={changeLanguage}
                  {...lang}
                  key={lang.code}
                />
              ))}
            </Dropdown>
          )}
          <button
            type="button"
            onClick={() => setIsOpen((prev) => !prev)}
            className={styles.selector_button}
          >
            <LanguageIcon lang={(i18n.language ?? "en") as LanguageCodes} />
          </button>
        </div>
      </div>
    </LanguageContainer>
  );
};

export default LanguageSelector;

interface LanguageRowProps {
  activeLanguage: LanguageCodes;
  code: LanguageCodes;
  label: LanguageLabels;
  onClick: (code: LanguageCodes) => void;
}

const LanguageRow = ({
  activeLanguage,
  code,
  label,
  onClick,
}: LanguageRowProps) => (
  <button
    type="button"
    onClick={() => onClick(code)}
    className={cx(styles.selector_row, {
      [styles.selector_row_active]: activeLanguage === code,
    })}
  >
    <LanguageIcon lang={code} />
    <Text className={styles.selector_label} variant="caption">
      {label}
    </Text>
    {activeLanguage === code && (
      <MdCheckCircle className={styles.selector_check} />
    )}
  </button>
);

const LanguageIcon = ({ lang }: { lang: LanguageCodes }) => (
  <div className={styles.selector_language}>
    <div
      className={cx(styles.selector_icon, {
        [styles.selector_icon_en]: lang === "en",
        [styles.selector_icon_ja]: lang === "ja",
      })}
    />
  </div>
);
