import { useState, useEffect } from "react";
import { getDimensionColour } from "utils/reportHelpers";
import defaultOptions from "./options";
import { CurrentDrilldown, DrilldownData, SetLegend } from "hooks/useReport";
import { useTranslation } from "react-i18next";
import { LanguageCodes } from "i18n/config";
import { QuestionNumbers } from "types/questions";
import { Dimensions } from "types/dimensions";
import { LineOptions } from "./options";
import isEmpty from "lodash/isEmpty";

export type Dataset = {
  barPercentage?: number;
  backgroundColor?: string;
  data: number[];
  label: string;
  borderColor?: string;
  borderWidth?: number;
  showLine?: boolean;
  pointStyle?: string;
  type?: "line";
};

export type Results = {
  question: string;
  datasets: Dataset[];
};

interface UseMostCommonAnswersGraphProps {
  activeTab: Dimensions;
  data: DrilldownData[];
  currentDrilldown: CurrentDrilldown;
  setLegend: SetLegend;
}

const commonDatasetOptions = {
  barPercentage: 0.6,
  borderWidth: 2,
};

export default function useMostCommonAnswersGraph({
  activeTab,
  data,
  currentDrilldown,
  setLegend,
}: UseMostCommonAnswersGraphProps) {
  const { t, ready: translationsReady, i18n } = useTranslation();
  const [options, setOptions] = useState(defaultOptions);
  const subDimensionsQuestions = data
    .filter((q) => q.qcode === currentDrilldown)
    .sort(sortDescendingByQuestion);

  useEffect(
    () =>
      setOptions((prev: LineOptions) => ({
        ...prev,
        scales: {
          ...prev.scales,
          x: {
            ...prev.scales!.x,
            ticks: {
              ...prev.scales!.x!.ticks,
              callback(label: string | number): string | null {
                switch (label) {
                  case 1:
                    return t("labels.Strongly_Agree");
                  case 2:
                    return t("labels.Agree");
                  case 3:
                    return t("labels.Not_Sure");
                  case 4:
                    return t("labels.Disagree");
                  case 5:
                    return t("labels.Strongly_Disagree");
                  default:
                    return null;
                }
              },
            },
          },
          y: {
            ...prev.scales!.y,
            ticks: {
              ...prev.scales!.y!.ticks,
              font: {
                ...prev.scales!.y!.ticks!.font,
                family: returnFontFamilyForLanguage(
                  i18n.language as LanguageCodes
                ),
              },
            },
          },
        },
      })),
    [i18n.language, t, translationsReady]
  );

  const results = subDimensionsQuestions.map<Results>((q) => {
    const [backgroundColor, borderColor] = getDimensionColour(activeTab);
    const questionNumber = q.question.slice(0, 5) as QuestionNumbers;

    const question = t(`questions.${questionNumber}`);
    const average_answer = t("mostCommonAnswers.average_answer");
    const your_answer = t("mostCommonAnswers.your_answer");

    if (q.averageAnswer === undefined) {
      return {
        question,
        datasets: [
          {
            ...commonDatasetOptions,
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            data: [answersToNumber[q.answer]],
            label: your_answer,
          },
        ],
      };
    }

    return {
      question,
      datasets: [
        {
          ...commonDatasetOptions,
          backgroundColor: "rgba(35, 20, 56, 0.4)",
          borderColor: "rgba(35, 20, 56, 1)",
          data: [answersToNumber[q.averageAnswer]],
          label: average_answer,
          pointRadius: 8,
          pointHoverRadius: 8,
          type: "line",
        },
        {
          ...commonDatasetOptions,
          backgroundColor: backgroundColor,
          borderColor: borderColor,
          data: [answersToNumber[q.answer]],
          label: your_answer,
          pointRadius: 16,
          pointHoverRadius: 16,
        },
      ],
    };
  });

  /**
   * Update legend with styles applied in the dataset
   */
  useEffect(() => {
    if (isEmpty(results)) return;
    const average_answer = t("mostCommonAnswers.average_answer");

    setLegend(
      results[0].datasets.map((d) => ({
        label: d.label,
        style: {
          backgroundColor: d.backgroundColor,
          border: `2px solid ${d.borderColor}`,
          borderRadius: "999px",
          ...(d.label === average_answer && {
            height: "16px",
            width: "16px",
          }),
        },
      }))
    );
  }, [results, setLegend]);

  return [results, options, i18n.language] as const;
}

const answersToNumber = {
  "Strongly Agree": 1,
  Agree: 2,
  "Not Sure": 3,
  Disagree: 4,
  "Strongly Disagree": 5,
};

function sortDescendingByQuestion(a: DrilldownData, b: DrilldownData) {
  if (a.question < b.question) return -1;
  if (a.question > b.question) return 1;
  return 0;
}

function returnFontFamilyForLanguage(language: LanguageCodes) {
  if (language === "ja") {
    return "Noto Sans JP";
  }

  return "GT Walsheim Pro";
}
