import React from "react";
import cx from "classnames";
import styles from "./DrawerBody.module.scss";

interface DrawerBodyProps {
  isOpen: boolean;
  children: React.ReactNode;
  className?: string;
}

type Ref = HTMLDivElement;

const DrawerBody = React.forwardRef<Ref, DrawerBodyProps>(
  ({ isOpen, children, className }, ref) => {
    return (
      <div
        ref={ref}
        className={cx(
          styles.drawer_body,
          {
            [styles.open]: isOpen,
            [styles.closed]: !isOpen,
          },
          className
        )}
      >
        {children}
      </div>
    );
  }
);

export default DrawerBody;
