import Loader from "components/generic/Loader";
import { DimensionsGraph, MostCommonAnswers } from "components/Graphs";
import PowerBI from "components/PowerBI";
import Recommendations from "components/Recommendations";
import ReportControls, {
  BreadCrumbs,
  ClientTasks,
  Legend,
  NavigationBar,
} from "components/ReportControls";
import useBreakpoints from "hooks/common/useBreakpoints";
import useReport, { State } from "hooks/useReport";
import { models } from "powerbi-client";
import React, { useEffect } from "react";
import { useErrorHandler } from "react-error-boundary";
import { Dimensions } from "types/dimensions";

export type InitialFilter = models.IBasicFilter;

interface ResultsDashboardProps {
  accessToken?: string;
  initialFilter?: InitialFilter;
  passedInitialState?: State;
  saveReportState?: (state: State) => void;
}

function ResultsDashboard({
  accessToken,
  initialFilter,
  passedInitialState,
  saveReportState,
}: ResultsDashboardProps) {
  const { state, clientHandlers, error, genericHandlers } = useReport({
    passedInitialState,
  });
  useErrorHandler(error);
  const breakpoints = useBreakpoints({ active: "md" });

  useEffect(() => {
    if (breakpoints.active === "sm" && !state.recommendationsIsActive) {
      clientHandlers.toggleRecommendations();
    }
  }, [breakpoints.active, state.recommendationsIsActive, clientHandlers]);

  useEffect(() => {
    // Clean up and wipe state on dismount to prevent any memory leaks
    return () => genericHandlers.wipeState();
  }, []);

  useEffect(() => {
    if (state.activity["dashboard-state"] === "isComplete" && saveReportState) {
      saveReportState(state);
    }
  }, [state.activity["dashboard-state"], saveReportState]);

  return (
    <React.Fragment>
      <ReportControls breakpoints={breakpoints}>
        <BreadCrumbs
          isLoading={state.activity["dashboard-state"] === "isLoading"}
          crumbs={state.crumbs}
        />
        <ClientTasks
          activeTab={state.activeTab}
          currentDrilldown={state.currentDrilldown}
          benchmarkIsActive={state.benchmarkIsActive}
          benchmarkAvailable={state.benchmarkAvailable}
          benchmarks={state.benchmarkData}
          isLoading={state.activity["dashboard-state"] === "isLoading"}
          clientScores={state.clientScores}
          toggleBenchmark={genericHandlers.toggleBenchmark}
          toggleRecommendations={clientHandlers.toggleRecommendations}
        />
        <NavigationBar
          isLoading={state.activity["dashboard-state"] === "isLoading"}
          activeTab={state.activeTab}
          setActiveTab={genericHandlers.setActiveTab}
          toggleDrilldown={genericHandlers.toggleDrilldown}
          currentDrilldown={state.currentDrilldown}
          setDrilldown={genericHandlers.setDrilldown}
          drilldownIsActive={state.drilldownIsActive}
        />
        <Legend
          isLoading={state.activity["dashboard-state"] === "isLoading"}
          list={state.legend}
        />
      </ReportControls>
      <Loader
        isLoading={state.activity["dashboard-state"] === "isLoading"}
        loadingText="Loading your report..."
      >
        {!passedInitialState && (
          <PowerBI
            isLoaded={state.activity["dashboard-state"] === "isComplete"}
            accessToken={accessToken}
            initialFilter={initialFilter}
            initializeReportCallback={genericHandlers.initializeReportCallback}
          />
        )}
        {state.currentDrilldown === undefined ? (
          <DimensionsGraph
            scores={state.clientScores}
            benchmarks={state.benchmarkData}
            activeTab={state.activeTab}
            benchmarkActive={state.benchmarkIsActive}
            surveyCodes={state.surveyCodes}
            setLegend={genericHandlers.setLegend}
          />
        ) : (
          <MostCommonAnswers
            setLegend={genericHandlers.setLegend}
            activeTab={state.activeTab as Dimensions}
            currentDrilldown={state.currentDrilldown}
            answerData={state.drilldownData}
          />
        )}
        <Recommendations
          benchmarkAvailable={state.benchmarkAvailable}
          benchmarks={state.benchmarkData}
          isLoading={state.activity["dashboard-state"] === "isLoading"}
          clientScores={state.clientScores}
          handleToggle={clientHandlers.toggleRecommendations}
          isActive={state.recommendationsIsActive}
          breakpoints={breakpoints}
        />
      </Loader>
    </React.Fragment>
  );
}

export default ResultsDashboard;
