import { Dimensions } from "types/dimensions";

export type Icons =
  | "GtRecycle"
  | "GtHandshakeHeart"
  | "GtPeopleTeam"
  | "GtHammer"
  | "GtComputerServers"
  | "GtComputerLaptop";

const getDimensionIcon = (dimension: Dimensions): Icons => {
  switch (dimension) {
    case "Sustainable Operating Model":
      return "GtRecycle";
    case "Customer":
      return "GtHandshakeHeart";
    case "Culture":
      return "GtPeopleTeam";
    case "Capabilities":
      return "GtHammer";
    case "Data":
      return "GtComputerServers";
    default:
      return "GtComputerLaptop";
  }
};

export default getDimensionIcon;
