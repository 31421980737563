import { State } from "hooks/useReport";

const testingData: State = {
  activity: {
    "dashboard-state": "isComplete",
  },
  activeTab: "Overview",
  benchmarkAvailable: true,
  benchmarkData: [
    {
      score: 7,
      dimension: "Capabilities",
      qcode: "Digital Skills",
    },
    {
      score: 7,
      dimension: "Capabilities",
      qcode: "Digital Workplace of the Future",
    },
    {
      score: 7,
      dimension: "Capabilities",
      qcode: "Learning, Development and Research",
    },
    {
      score: 9,
      dimension: "Capabilities",
      qcode: "Talent Retention, Growth and Engagement",
    },
    {
      score: 7,
      dimension: "Culture",
      qcode: "Communication and Collaboration",
    },
    {
      score: 8,
      dimension: "Culture",
      qcode: "Diversity & Inclusion",
    },
    {
      score: 10,
      dimension: "Culture",
      qcode: "Experimentation",
    },
    {
      score: 11,
      dimension: "Culture",
      qcode: "Organisational Adaptability",
    },
    {
      score: 8,
      dimension: "Customer",
      qcode: "Customer Insights",
    },
    {
      score: 9,
      dimension: "Customer",
      qcode: "Customer Experience",
    },
    {
      score: 12,
      dimension: "Customer",
      qcode: "Customer Engagement",
    },
    {
      score: 13,
      dimension: "Customer",
      qcode: "Customer Trust",
    },
    {
      score: 8,
      dimension: "Data",
      qcode: "Data Platforms",
    },
    {
      score: 9,
      dimension: "Data",
      qcode: "Data Science and Big Data",
    },
    {
      score: 10,
      dimension: "Data",
      qcode: "Data Analytics and Visualisation",
    },
    {
      score: 11,
      dimension: "Data",
      qcode: "Data Strategy and Governance",
    },
    {
      score: 6,
      dimension: "Sustainable Operating Model",
      qcode: "Agile Process and Procedures",
    },
    {
      score: 9,
      dimension: "Sustainable Operating Model",
      qcode: "Intelligent Workflow and Operations",
    },
    {
      score: 9,
      dimension: "Sustainable Operating Model",
      qcode: "Advanced Ecosystems and Partnerships Engagement",
    },
    {
      score: 11,
      dimension: "Sustainable Operating Model",
      qcode: "Integrated Channels",
    },
    {
      score: 7,
      dimension: "Technology",
      qcode: "Focused Security",
    },
    {
      score: 9,
      dimension: "Technology",
      qcode: "Intelligent Automation",
    },
    {
      score: 9,
      dimension: "Technology",
      qcode: "Enabled DevOps",
    },
    {
      score: 10,
      dimension: "Technology",
      qcode: "Optimised Infrastructure, Architecture and Applications",
    },
  ],
  benchmarkIsActive: false,
  clientScores: [
    {
      dimension: "Capabilities",
      qcode: "Digital Skills",
      score: 4,
    },
    {
      dimension: "Capabilities",
      qcode: "Digital Workplace of the Future",
      score: 3,
    },
    {
      dimension: "Capabilities",
      qcode: "Learning, Development and Research",
      score: 7,
    },
    {
      dimension: "Capabilities",
      qcode: "Talent Retention, Growth and Engagement",
      score: 6,
    },
    {
      dimension: "Culture",
      qcode: "Communication and Collaboration",
      score: 3,
    },
    {
      dimension: "Culture",
      qcode: "Diversity & Inclusion",
      score: 6,
    },
    {
      dimension: "Culture",
      qcode: "Experimentation",
      score: 10,
    },
    {
      dimension: "Culture",
      qcode: "Organisational Adaptability",
      score: 10,
    },
    {
      dimension: "Customer",
      qcode: "Customer Engagement",
      score: 14,
    },
    {
      dimension: "Customer",
      qcode: "Customer Experience",
      score: 7,
    },
    {
      dimension: "Customer",
      qcode: "Customer Insights",
      score: 7,
    },
    {
      dimension: "Customer",
      qcode: "Customer Trust",
      score: 14,
    },
    {
      dimension: "Data",
      qcode: "Data Analytics and Visualisation",
      score: 7,
    },
    {
      dimension: "Data",
      qcode: "Data Platforms",
      score: 3,
    },
    {
      dimension: "Data",
      qcode: "Data Science and Big Data",
      score: 7,
    },
    {
      dimension: "Data",
      qcode: "Data Strategy and Governance",
      score: 10,
    },
    {
      dimension: "Sustainable Operating Model",
      qcode: "Advanced Ecosystems and Partnerships Engagement",
      score: 9,
    },
    {
      dimension: "Sustainable Operating Model",
      qcode: "Agile Process and Procedures",
      score: 6,
    },
    {
      dimension: "Sustainable Operating Model",
      qcode: "Integrated Channels",
      score: 10,
    },
    {
      dimension: "Sustainable Operating Model",
      qcode: "Intelligent Workflow and Operations",
      score: 7,
    },
    {
      dimension: "Technology",
      qcode: "Enabled DevOps",
      score: 10,
    },
    {
      dimension: "Technology",
      qcode: "Focused Security",
      score: 4,
    },
    {
      dimension: "Technology",
      qcode: "Intelligent Automation",
      score: 7,
    },
    {
      dimension: "Technology",
      qcode: "Optimised Infrastructure, Architecture and Applications",
      score: 10,
    },
  ],
  crumbs: ["Overview"],
  currentDrilldown: undefined,
  dimensionChartData: [],
  drilldownData: [
    {
      question:
        "1.1.1 Your organisation / department understands why a digital engagement strategy is important",
      answer: "Strongly Agree",
      qcode: "Integrated Channels",
      averageAnswer: "Strongly Agree",
    },
    {
      question:
        "1.1.2 Your organisation / department is consistently represented across different media and communications platforms (e.g. web, social, email)",
      answer: "Agree",
      qcode: "Integrated Channels",
      averageAnswer: "Agree",
    },
    {
      question:
        "1.1.3 Your organisation's / department's customers know how to find you across web, social, email, search, mobile and other digital means",
      answer: "Not Sure",
      qcode: "Integrated Channels",
      averageAnswer: "Not Sure",
    },
    {
      question:
        "1.1.4 Your organisation / department has a defined Search Engine Optimisation strategy and executes it well",
      answer: "Agree",
      qcode: "Integrated Channels",
      averageAnswer: "Agree",
    },
    {
      question:
        "1.1.5 Your organisation's / department's marketing strategy drives your digital engagement strategy",
      answer: "Disagree",
      qcode: "Integrated Channels",
      averageAnswer: "Strongly Agree",
    },
    {
      question:
        "1.2.1 Your organisation / department has digital-savvy leaders",
      answer: "Agree",
      qcode: "Agile Process and Procedures",
      averageAnswer: "Not Sure",
    },
    {
      question:
        "1.2.2 Your organisation's / department's Leadership Team recognises the value of digital processes",
      answer: "Not Sure",
      qcode: "Agile Process and Procedures",
      averageAnswer: "Not Sure",
    },
    {
      question:
        "1.2.3 Your organisation's / department's Leadership Team recognises, communicates and rewards innovative thinking",
      answer: "Agree",
      qcode: "Agile Process and Procedures",
      averageAnswer: "Agree",
    },
    {
      question:
        "1.2.4 Your organisation's / department's Management Team has the ability to make quick strategic decisions",
      answer: "Strongly Agree",
      qcode: "Agile Process and Procedures",
      averageAnswer: "Agree",
    },
    {
      question:
        "1.2.5 Your organisation's / department's Leadership Team works collaboratively to drive strategic ambition",
      answer: "Not Sure",
      qcode: "Agile Process and Procedures",
      averageAnswer: "Strongly Agree",
    },
    {
      question:
        "1.3.1 The IT strategy of your organisation / department is outward looking and future focused",
      answer: "Agree",
      qcode: "Intelligent Workflow and Operations",
      averageAnswer: "Agree",
    },
    {
      question:
        "1.3.2 The development of digital capabilities is at the core of your organisation's / department's business strategy",
      answer: "Strongly Agree",
      qcode: "Intelligent Workflow and Operations",
      averageAnswer: "Agree",
    },
    {
      question:
        "1.3.3 Your organisation / department business strategy is responsive and adaptive and can quickly change based on insights and organisational priorities",
      answer: "Strongly Disagree",
      qcode: "Intelligent Workflow and Operations",
      averageAnswer: "Agree",
    },
    {
      question:
        "1.3.4 Your organisation's / department's purpose and culture are intrinsically linked to business strategy",
      answer: "Disagree",
      qcode: "Intelligent Workflow and Operations",
      averageAnswer: "Agree",
    },
    {
      question:
        "1.3.5 Your organisation / department teams are organised around continual experimentation, exploration and learning",
      answer: "Not Sure",
      qcode: "Intelligent Workflow and Operations",
      averageAnswer: "Not Sure",
    },
    {
      question:
        "1.4.1 Your organisation / department embraces design thinking to create holistic and high value end-to-end experiences",
      answer: "Disagree",
      qcode: "Advanced Ecosystems and Partnerships Engagement",
      averageAnswer: "Disagree",
    },
    {
      question:
        "1.4.2 Your organisation / department runs initiatives to solicit creative solutions to some of the toughest business and product challenges",
      answer: "Agree",
      qcode: "Advanced Ecosystems and Partnerships Engagement",
      averageAnswer: "Agree",
    },
    {
      question:
        "1.4.3 Your organisation / department has a strong partnership model to collaborate with third parties",
      answer: "Not Sure",
      qcode: "Advanced Ecosystems and Partnerships Engagement",
      averageAnswer: "Strongly Disagree",
    },
    {
      question:
        "1.4.4 Your organisation / department holds sessions and meetings to collaborate on new ideas with employees, customers or third party providers",
      answer: "Agree",
      qcode: "Advanced Ecosystems and Partnerships Engagement",
      averageAnswer: "Agree",
    },
    {
      question:
        "1.4.5 Your organisation / department is open to cooperating with competitors",
      answer: "Agree",
      qcode: "Advanced Ecosystems and Partnerships Engagement",
      averageAnswer: "Agree",
    },
    {
      question:
        "2.1.1 Your organisation / department sees digital skills as a key competitive advantage",
      answer: "Strongly Agree",
      qcode: "Digital Skills",
      averageAnswer: "Strongly Agree",
    },
    {
      question:
        "2.1.2 Your organisation / department is engaged with new technologies and is keen to understand how they can improve current processes",
      answer: "Not Sure",
      qcode: "Digital Skills",
      averageAnswer: "Not Sure",
    },
    {
      question:
        "2.1.3 Your organisation / department promotes digital skills as a way of improving individual and team effectiveness",
      answer: "Disagree",
      qcode: "Digital Skills",
      averageAnswer: "Agree",
    },
    {
      question:
        "2.1.4 Your organisations / department's employees are able to quickly find, share and update information on your internal systems",
      answer: "Agree",
      qcode: "Digital Skills",
      averageAnswer: "Agree",
    },
    {
      question:
        "2.1.5 Your organisation / department actively measures and tracks digital skills maturity for teams and individuals",
      answer: "Strongly Disagree",
      qcode: "Digital Skills",
      averageAnswer: "Agree",
    },
    {
      question:
        "2.2.1 Your organisation / department has sufficient digital development programs and encourages staff to continuously upskill",
      answer: "Agree",
      qcode: "Learning, Development and Research",
      averageAnswer: "Agree",
    },
    {
      question:
        "2.2.2 Your organisation / department provides incentives and uses metrics to reward employees who drive progress in innovation",
      answer: "Not Sure",
      qcode: "Learning, Development and Research",
      averageAnswer: "Agree",
    },
    {
      question:
        "2.2.3 Your organisation's / department's employees are empowered to implement change",
      answer: "Agree",
      qcode: "Learning, Development and Research",
      averageAnswer: "Agree",
    },
    {
      question:
        "2.2.4 Your organisation / department encourages employees to learn from failure",
      answer: "Disagree",
      qcode: "Learning, Development and Research",
      averageAnswer: "Agree",
    },
    {
      question:
        "2.2.5 Research and Development is a key component to the organisation's / department's business performance and value creation",
      answer: "Strongly Agree",
      qcode: "Learning, Development and Research",
      averageAnswer: "Strongly Agree",
    },
    {
      question:
        "2.3.1 Your organisation / department is adequately equipped to embark on a digital transformation journey",
      answer: "Agree",
      qcode: "Talent Retention, Growth and Engagement",
      averageAnswer: "Agree",
    },
    {
      question:
        "2.3.2 Your organisation / department recognises the requirement for the right resources and works towards reducing the digital talent gap",
      answer: "Not Sure",
      qcode: "Talent Retention, Growth and Engagement",
      averageAnswer: "Agree",
    },
    {
      question:
        "2.3.3 An open collaborative working environment is encouraged within your organisation / department",
      answer: "Disagree",
      qcode: "Talent Retention, Growth and Engagement",
      averageAnswer: "Not Sure",
    },
    {
      question:
        "2.3.4 In your organisation / department employees strengths, working styles and aspirations are understood by management",
      answer: "Agree",
      qcode: "Talent Retention, Growth and Engagement",
      averageAnswer: "Strongly Agree",
    },
    {
      question:
        "2.3.5 In your organisation / department employees can discuss future/alternative career paths with their managers",
      answer: "Not Sure",
      qcode: "Talent Retention, Growth and Engagement",
      averageAnswer: "Agree",
    },
    {
      question:
        "2.4.1 Your organisation / department encourages the use of digital workplaces as ways of working",
      answer: "Agree",
      qcode: "Digital Workplace of the Future",
      averageAnswer: "Agree",
    },
    {
      question:
        "2.4.2 Your organisation / department is setup for a fully distributed digital operating model",
      answer: "Disagree",
      qcode: "Digital Workplace of the Future",
      averageAnswer: "Agree",
    },
    {
      question:
        "2.4.3 Information provided digitally within your workplace can inform quick decisions and improve business performance",
      answer: "Not Sure",
      qcode: "Digital Workplace of the Future",
      averageAnswer: "Not Sure",
    },
    {
      question:
        "2.4.4 In your organisation / department employees are encouraged to experiment with new ways of working",
      answer: "Disagree",
      qcode: "Digital Workplace of the Future",
      averageAnswer: "Strongly Agree",
    },
    {
      question:
        "2.4.5 There is a culture of rapid sharing of best practices across your organisation / department",
      answer: "Not Sure",
      qcode: "Digital Workplace of the Future",
      averageAnswer: "Agree",
    },
    {
      question:
        "3.1.1 Your organisation / department considers a Chief Data Officer to be a key member of your organisation",
      answer: "Strongly Agree",
      qcode: "Focused Security",
      averageAnswer: "Strongly Agree",
    },
    {
      question:
        "3.1.2 There are processes in place to assess and track your employees’ security understandings, education and training",
      answer: "Not Sure",
      qcode: "Focused Security",
      averageAnswer: "Agree",
    },
    {
      question:
        "3.1.3 Your organisation / department has processes in place to ensure compliance with regulatory requirements in line with your industry",
      answer: "Disagree",
      qcode: "Focused Security",
      averageAnswer: "Disagree",
    },
    {
      question:
        "3.1.4 In your organisation / department you identify and inventory data assets and processes in relation to the storage, the processing of personal data and backups",
      answer: "Disagree",
      qcode: "Focused Security",
      averageAnswer: "Agree",
    },
    {
      question:
        "3.1.5 Your organisation / department secures all sensitive data, while in storage and also while being transmitted",
      answer: "Not Sure",
      qcode: "Focused Security",
      averageAnswer: "Agree",
    },
    {
      question:
        "3.2.1 In your organisation / department changes to new IT services and processes are encouraged, adopted, implemented, and supported",
      answer: "Agree",
      qcode: "Optimised Infrastructure, Architecture and Applications",
      averageAnswer: "Agree",
    },
    {
      question:
        "3.2.2 Your organisation / department quickly releases technology in the form of a Minimum Viable Product",
      answer: "Not Sure",
      qcode: "Optimised Infrastructure, Architecture and Applications",
      averageAnswer: "Not Sure",
    },
    {
      question:
        "3.2.3 Technology business value is regularly reviewed in your organisation / department to determine next steps (Continue Operations Modify Retire…)",
      answer: "Strongly Agree",
      qcode: "Optimised Infrastructure, Architecture and Applications",
      averageAnswer: "Strongly Agree",
    },
    {
      question:
        "3.2.4 Your organisation / department promotes the use of digital solutions to create efficiencies",
      answer: "Agree",
      qcode: "Optimised Infrastructure, Architecture and Applications",
      averageAnswer: "Agree",
    },
    {
      question:
        "3.2.5 Your organisation / department continually invests in process improvements and digital tools",
      answer: "Not Sure",
      qcode: "Optimised Infrastructure, Architecture and Applications",
      averageAnswer: "Agree",
    },
    {
      question:
        "3.3.1 Your organisation / department has a full set of skills in-house to maintain, operate and manage applications",
      answer: "Agree",
      qcode: "Enabled DevOps",
      averageAnswer: "Not Sure",
    },
    {
      question:
        "3.3.2 Your organisation / department releases the DevOps application frequently",
      answer: "Not Sure",
      qcode: "Enabled DevOps",
      averageAnswer: "Agree",
    },
    {
      question:
        "3.3.3 The DevOps application architecture is evolving continuously based on customer release requirements",
      answer: "Strongly Disagree",
      qcode: "Enabled DevOps",
      averageAnswer: "Agree",
    },
    {
      question:
        "3.3.4 Your organisation / department considers machine learning and AI tools to optimize activities throughout the DevOps pipeline",
      answer: "Strongly Agree",
      qcode: "Enabled DevOps",
      averageAnswer: "Strongly Agree",
    },
    {
      question:
        "3.3.5 Your organisation / department manages a full DevOps lifecycle, from ideation through to production",
      answer: "Agree",
      qcode: "Enabled DevOps",
      averageAnswer: "Agree",
    },
    {
      question:
        "3.4.1 There is a process for managing experimentation programs, such as linking ideas/suggestions with KPIs to ensure their progress",
      answer: "Disagree",
      qcode: "Intelligent Automation",
      averageAnswer: "Strongly Agree",
    },
    {
      question:
        "3.4.2 Your organisation / department has the appropriate analytical resources and tools",
      answer: "Agree",
      qcode: "Intelligent Automation",
      averageAnswer: "Agree",
    },
    {
      question:
        "3.4.3 Your organisation / department has robust systems to support and record data in order to provide strategic insights",
      answer: "Disagree",
      qcode: "Intelligent Automation",
      averageAnswer: "Not Sure",
    },
    {
      question:
        "3.4.4 Digitalisation, automation and other technological advancements are a priority for your organisation / department",
      answer: "Disagree",
      qcode: "Intelligent Automation",
      averageAnswer: "Agree",
    },
    {
      question:
        "3.4.5 Your organisation / department continually seeks to adopt automation, especially for operational activities that occur on a repetitive basis",
      answer: "Strongly Agree",
      qcode: "Intelligent Automation",
      averageAnswer: "Strongly Agree",
    },
    {
      question:
        '4.1.1 Your organisation / department encourages ""Out of the Box"" thinking and creators are given space and safety to share new ideas',
      answer: "Agree",
      qcode: "Experimentation",
      averageAnswer: "Agree",
    },
    {
      question:
        "4.1.2 Your organisation's / department's culture supports agility and new ways of working, and cross team collaboration and flexibility are encouraged",
      answer: "Not Sure",
      qcode: "Experimentation",
      averageAnswer: "Strongly Agree",
    },
    {
      question:
        "4.1.3 Your organisation / department has mechanisms in place to drive the best ideas forward and to discard non-viable ideas early on",
      answer: "Strongly Agree",
      qcode: "Experimentation",
      averageAnswer: "Agree",
    },
    {
      question:
        "4.1.4 Your organisation / department has a culture where it is acceptable to be wrong and sees failure as an opportunity for learning",
      answer: "Agree",
      qcode: "Experimentation",
      averageAnswer: "Agree",
    },
    {
      question:
        "4.1.5 Your organisation praises all efforts regardless of whether an idea is successful or not",
      answer: "Agree",
      qcode: "Experimentation",
      averageAnswer: "Agree",
    },
    {
      question:
        "4.2.1 Your organisation's / department's employees come from a variety of backgrounds, experiences and ages",
      answer: "Not Sure",
      qcode: "Diversity & Inclusion",
      averageAnswer: "Agree",
    },
    {
      question:
        "4.2.2 Your organisation / department has a mixed skill set of digital and non-digital employees",
      answer: "Disagree",
      qcode: "Diversity & Inclusion",
      averageAnswer: "Agree",
    },
    {
      question:
        "4.2.3 Your organisation / department welcomes and encourages challenge, and differences of opinion",
      answer: "Agree",
      qcode: "Diversity & Inclusion",
      averageAnswer: "Agree",
    },
    {
      question:
        "4.2.4 Your organisation / department promotes Diversity and Inclusion at hiring stages",
      answer: "Disagree",
      qcode: "Diversity & Inclusion",
      averageAnswer: "Disagree",
    },
    {
      question:
        "4.2.5 Your organisation / department conducts anonymous surveys to gather feedback on culture, inclusion hiring and promotional processes",
      answer: "Agree",
      qcode: "Diversity & Inclusion",
      averageAnswer: "Agree",
    },
    {
      question:
        "4.3.1 Your organisation / department shares the decision making processes behind projects",
      answer: "Not Sure",
      qcode: "Communication and Collaboration",
      averageAnswer: "Agree",
    },
    {
      question:
        "4.3.2 Your organisation / department provides accurate, consistent and transparent information around products and services to stakeholders",
      answer: "Strongly Disagree",
      qcode: "Communication and Collaboration",
      averageAnswer: "Agree",
    },
    {
      question:
        "4.3.3 Your organisation / department regularly shares and discusses what is working, what is not working, and how it is progressing",
      answer: "Disagree",
      qcode: "Communication and Collaboration",
      averageAnswer: "Agree",
    },
    {
      question:
        "4.3.4 Ideas are shared openly across the organisation / department",
      answer: "Agree",
      qcode: "Communication and Collaboration",
      averageAnswer: "Agree",
    },
    {
      question:
        "4.3.5 Employees are comfortable giving feedback, receiving feedback and enhancing each others ideas to add value to the business",
      answer: "Not Sure",
      qcode: "Communication and Collaboration",
      averageAnswer: "Agree",
    },
    {
      question:
        "4.4.1 Your organisation's / department's goals and corporate culture promotes and encourages change as a key competitive advantage",
      answer: "Strongly Agree",
      qcode: "Organisational Adaptability",
      averageAnswer: "Agree",
    },
    {
      question:
        "4.4.2 Your organisation / department encourages creativity and models it as a business practice",
      answer: "Agree",
      qcode: "Organisational Adaptability",
      averageAnswer: "Strongly Agree",
    },
    {
      question:
        "4.4.3 Your organisation / department promotes a problem-solving mindset and readily identifies innovative ideas from internal teams",
      answer: "Not Sure",
      qcode: "Organisational Adaptability",
      averageAnswer: "Agree",
    },
    {
      question:
        "4.4.4 Your organisation / department is able to respond quickly to new commercial opportunities, and does so with appropriate controls",
      answer: "Not Sure",
      qcode: "Organisational Adaptability",
      averageAnswer: "Strongly Agree",
    },
    {
      question:
        "4.4.5 Your organisation / department has a defined and well-managed risk register and understands the thematic risks within your firm",
      answer: "Agree",
      qcode: "Organisational Adaptability",
      averageAnswer: "Agree",
    },
    {
      question:
        "5.1.1 There is clear lineage between your organisation's business and data strategy, and this has been communicated across the firm",
      answer: "Agree",
      qcode: "Data Strategy and Governance",
      averageAnswer: "Agree",
    },
    {
      question:
        "5.1.2 Your organisation treats data as a key business asset and seeks to use it as a competitive advantage",
      answer: "Strongly Agree",
      qcode: "Data Strategy and Governance",
      averageAnswer: "Agree",
    },
    {
      question:
        "5.1.3 Your organisation has policies and procedures in place to classify data, and this data follows a defined lifecycle",
      answer: "Not Sure",
      qcode: "Data Strategy and Governance",
      averageAnswer: "Not Sure",
    },
    {
      question:
        "5.1.4 You organisation has a defined workflow to identify data owners/stewards, and they are empowered to manage data processes",
      answer: "Disagree",
      qcode: "Data Strategy and Governance",
      averageAnswer: "Agree",
    },
    {
      question:
        "5.1.5 Your organisation has appropriate governance forums in place to provide oversight of all aspects of data, and has strong business representation",
      answer: "Agree",
      qcode: "Data Strategy and Governance",
      averageAnswer: "Agree",
    },
    {
      question:
        "5.2.1 Your organisation understands the ethical risks associated with data science and machine learning, and takes an active role in managing these risks",
      answer: "Not Sure",
      qcode: "Data Science and Big Data",
      averageAnswer: "Strongly Agree",
    },
    {
      question:
        "5.2.2 Your organisation's data strategy includes ways in which data science and machine learning can improve efficiency and transform business processes",
      answer: "Strongly Disagree",
      qcode: "Data Science and Big Data",
      averageAnswer: "Strongly Agree",
    },
    {
      question:
        "5.2.3 Your organisation stores necessary data only, all data has a defined retention period which has been agreed by data type and use case",
      answer: "Disagree",
      qcode: "Data Science and Big Data",
      averageAnswer: "Agree",
    },
    {
      question:
        "5.2.4 Your organisation understands what data science is, and how it has the potential to transform your business and the industry you operate in",
      answer: "Strongly Agree",
      qcode: "Data Science and Big Data",
      averageAnswer: "Strongly Agree",
    },
    {
      question:
        "5.2.5 Your organisation has sought to help people understand how their jobs might change positively as a result of AI and ML techniques",
      answer: "Agree",
      qcode: "Data Science and Big Data",
      averageAnswer: "Agree",
    },
    {
      question:
        "5.3.1 Your organisation has the Management Information (MI), reporting and on-demand analytics required to manage risks, control P&L, and to identify competitive opportunities",
      answer: "Disagree",
      qcode: "Data Analytics and Visualisation",
      averageAnswer: "Strongly Agree",
    },
    {
      question:
        '5.3.2 Your organisation advocates and adheres to the concept of ""one version of the truth"" in your MI and reporting',
      answer: "Not Sure",
      qcode: "Data Analytics and Visualisation",
      averageAnswer: "Agree",
    },
    {
      question:
        "5.3.3 Your data visualisation tools are developed with a consistent design / user interface, making them easy to use for all staff",
      answer: "Agree",
      qcode: "Data Analytics and Visualisation",
      averageAnswer: "Agree",
    },
    {
      question:
        "5.3.4 Your organisation's MI reporting, dashboards and advanced analytics use data from a centralised data warehouse, and not from other sources",
      answer: "Strongly Agree",
      qcode: "Data Analytics and Visualisation",
      averageAnswer: "Strongly Agree",
    },
    {
      question:
        "5.3.5 All parties within our organisation use centralised, approved tools and processes to consume and use data",
      answer: "Strongly Disagree",
      qcode: "Data Analytics and Visualisation",
      averageAnswer: "Agree",
    },
    {
      question:
        "5.4.1 Your organisation's data platforms are fit-for-purpose, and allow data to be accessed by those who need it, when they need it",
      answer: "Disagree",
      qcode: "Data Platforms",
      averageAnswer: "Agree",
    },
    {
      question:
        "5.4.2 All data follows a prescribed path through the organisation, from generation and capture through to consumption by end users, processes and systems",
      answer: "Not Sure",
      qcode: "Data Platforms",
      averageAnswer: "Not Sure",
    },
    {
      question:
        "5.4.3 Your organisation's data platform integrates useful and relevant data from outside the firm, such as social media, industry data and news",
      answer: "Disagree",
      qcode: "Data Platforms",
      averageAnswer: "Agree",
    },
    {
      question:
        "5.4.4 Employees in your organisation / department know how to request new data sources be added to the data platform",
      answer: "Agree",
      qcode: "Data Platforms",
      averageAnswer: "Agree",
    },
    {
      question:
        "5.4.5 Data joins / definitions for key reporting fields are published centrally, and data is presented as curated data sets in pre-built cubes where possible",
      answer: "Disagree",
      qcode: "Data Platforms",
      averageAnswer: "Agree",
    },
    {
      question:
        "6.1.1 Your organisation / department is aware of the requirement to identify principal stakeholders quickly",
      answer: "Strongly Agree",
      qcode: "Customer Engagement",
      averageAnswer: "Strongly Agree",
    },
    {
      question:
        "6.1.2 Your organisation / department understands the need to identify where the principal stakeholders sit in their organisation",
      answer: "Agree",
      qcode: "Customer Engagement",
      averageAnswer: "Agree",
    },
    {
      question:
        "6.1.3 Your organisation / department seeks to speedily qualify the priority objectives of the principal stakeholders",
      answer: "Not Sure",
      qcode: "Customer Engagement",
      averageAnswer: "Agree",
    },
    {
      question:
        "6.1.4 Your organisation / department has a process to determine why the principal stakeholders wish to engage",
      answer: "Strongly Agree",
      qcode: "Customer Engagement",
      averageAnswer: "Agree",
    },
    {
      question:
        "6.1.5 Your organisation / department is clear that it understands the timeframes of principal stakeholders in obtaining a solution",
      answer: "Agree",
      qcode: "Customer Engagement",
      averageAnswer: "Agree",
    },
    {
      question:
        "6.2.1 Your organisation / department has a process to identify the cohorts of individuals who will be representative of the customer",
      answer: "Disagree",
      qcode: "Customer Experience",
      averageAnswer: "Agree",
    },
    {
      question:
        "6.2.2 An approach to profile the cohorts of individuals who will be representative of the customer has been determined",
      answer: "Agree",
      qcode: "Customer Experience",
      averageAnswer: "Agree",
    },
    {
      question:
        "6.2.3 Your organisation / department has a process by which to understand what the cohorts of individuals are seeking",
      answer: "Strongly Agree",
      qcode: "Customer Experience",
      averageAnswer: "Agree",
    },
    {
      question:
        "6.2.4 There is a process in place to understanding the motivations that drive the cohorts of individuals",
      answer: "Strongly Disagree",
      qcode: "Customer Experience",
      averageAnswer: "Agree",
    },
    {
      question:
        "6.2.5 Your organisation / department has a method to understand the timelines in which the cohorts of individuals are seeking a result",
      answer: "Disagree",
      qcode: "Customer Experience",
      averageAnswer: "Not Sure",
    },
    {
      question:
        "6.3.1 Your organisation / department has a solution to capture and analyse information on the customer",
      answer: "Not Sure",
      qcode: "Customer Insights",
      averageAnswer: "Agree",
    },
    {
      question:
        "6.3.2 Your organisation / department has a mechanism by which to determine and revise the key dimensions of customer profiling",
      answer: "Agree",
      qcode: "Customer Insights",
      averageAnswer: "Agree",
    },
    {
      question:
        "6.3.3 Your organisation / department has a tool with which to present information on the customer in a meaningful way",
      answer: "Not Sure",
      qcode: "Customer Insights",
      averageAnswer: "Agree",
    },
    {
      question:
        "6.3.4 Your organisation / department has a procedure through which external factors and considerations on the customer can be put forward",
      answer: "Strongly Agree",
      qcode: "Customer Insights",
      averageAnswer: "Strongly Agree",
    },
    {
      question:
        "6.3.5 Your organisation / department has a platform by which information on the customer can analysed in a timely manner",
      answer: "Agree",
      qcode: "Customer Insights",
      averageAnswer: "Agree",
    },
    {
      question:
        "6.4.1 Front-line, customer facing areas of the business have a clear definition and understanding as to what is meant by 'customer trust'",
      answer: "Not Sure",
      qcode: "Customer Trust",
      averageAnswer: "Not Sure",
    },
    {
      question:
        "6.4.2 Customer facing business areas understand that customer trust is of value and importance",
      answer: "Agree",
      qcode: "Customer Trust",
      averageAnswer: "Agree",
    },
    {
      question:
        "6.4.3 Your front-line, customer facing areas understand the factors that drive customer trust",
      answer: "Strongly Agree",
      qcode: "Customer Trust",
      averageAnswer: "Strongly Agree",
    },
    {
      question:
        "6.4.4 Front-line, customer facing business areas appreciate why customer trust is so important",
      answer: "Agree",

      qcode: "Customer Trust",
      averageAnswer: "Strongly Agree",
    },
    {
      question:
        "6.4.5 Your front-line, customer facing areas understand the impact when customer trust is lost",
      answer: "Strongly Agree",
      qcode: "Customer Trust",
      averageAnswer: "Strongly Agree",
    },
  ],
  drilldownIsActive: false,
  filterDrawerState: false,
  legend: [
    {
      label: "Your score",
      style: {
        backgroundColor: "rgba(35, 20, 56, 0.4)",
        border: "2px solid rgba(35, 20, 56, 1)",
      },
    },
  ],
  recommendationsIsActive: false,
  surveyCodes: [],
  surveyDataPage: undefined,
};

export default testingData;
