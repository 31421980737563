import React from "react";
import useTooltip from "../hooks/useTooltip";
import styles from "./Tooltip.module.scss";
import Text from "../Text/Text";
import { UseTooltipProps } from "./../hooks/useTooltip";

type TooltipChildrenProps = {
  setReferenceElement: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

interface TooltipProps
  extends Pick<
    UseTooltipProps,
    "popperPlacement" | "popperEnabled" | "popperOffset"
  > {
  children: (props: TooltipChildrenProps) => JSX.Element;
  label: string;
}

const Tooltip = ({
  children,
  label,
  popperPlacement = "left",
  popperEnabled = true,
  popperOffset = [0, 0],
}: TooltipProps) => {
  const {
    attributes,
    popperStyles,
    setArrowElement,
    setPopperElement,
    setReferenceElement,
    setVisible,
    visible,
  } = useTooltip({ popperPlacement, popperEnabled, popperOffset });
  return (
    <>
      {children({
        setReferenceElement,
        setVisible,
      })}
      {visible && (
        <div
          ref={setPopperElement}
          style={popperStyles.popper}
          className={styles.popper}
          {...attributes.popper}
        >
          <div className={styles.tooltip}>
            <Text variant="caption">{label}</Text>
          </div>
          <div ref={setArrowElement} style={popperStyles.arrow} />
        </div>
      )}
    </>
  );
};

export default Tooltip;
