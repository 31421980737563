import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { models, Page, Report, VisualDescriptor } from "powerbi-client";
import { useCallback, useReducer, useState } from "react";
import {
  Dimensions,
  DimensionsTranslationKeys,
  SubDimensions,
  SubDimensionsTranslationKeys,
} from "types/dimensions";
import {
  mergeAnswersData,
  parseClientsAnswers,
  parseMostCommmonAnswers,
  parseSubDimension,
  parseSubDimensionBenchmarks,
  parseTotalSubmissions,
} from "utils/function";
import {
  checkBenchmarkData,
  checkDrilldownData,
  checkSubDimensionsData,
  pulledVisualsIsIncorrect,
  returnVisualsForExporting,
} from "utils/reportHelpers";

/**
 * Tabs ranked in order by how they appear in the survey.
 */
export const TABS: TabsType[] = [
  "Overview",
  "Sustainable Operating Model",
  "Capabilities",
  "Technology",
  "Culture",
  "Data",
  "Customer",
];

export type PowerBiVisualTitles =
  | "Total submissions"
  | "Answers"
  | "Most common answers"
  | "Benchmark scores"
  | "Scores";

export type Ranks = "Nascent" | "Developing" | "Maturing" | "Leading";

export type CrumbType = Dimensions | SubDimensions | "Overview";

export type AnswerLabels =
  | "Strongly Agree"
  | "Agree"
  | "Not Sure"
  | "Disagree"
  | "Strongly Disagree";

type Activities = "dashboard-state";

type Status = "isLoading" | "isComplete";

export type TabsType = Dimensions | "Overview";

export type AddInitialSurveyCode = () => void;
export type AddSurveyCodes = (code: string[]) => void;
export type AddSingleSurveyCode = (code: string) => void;
export type ClearSurveyCodes = () => void;
export type InitializeReportCallback = (report: Report) => Promise<void>;
export type RemoveSurveyCode = (code: string) => void;
export type ResetGraph = () => void;
export type SetActiveTab = (tab: TabsType) => void;
export type SetBenchmarks = (benchmarks: BenchmarkData[]) => void;
export type SetBenchmarkAvailable = (totalSubmissions: number) => void;
export type SetClientScores = (scores: Scores[]) => void;
export type SetDashboardReady = () => void;
export type SetDrilldown = (subDimension: SubDimensions) => void;
export type SetDrilldownData = (data: DrilldownData[]) => void;
export type SetLegend = (legend: LegendItemType[]) => void;
export type ToggleBenchmark = (state?: boolean) => void;
export type ToggleDrilldown = (state?: boolean) => void;
export type ToggleFilterDrawer = (state?: boolean) => void;
export type ToggleRecommendations = (state?: boolean) => void;
export type UnsetDashboardReady = () => void;
export type WipeState = () => void;

export interface Handlers {
  addInitialSurveyCode: AddInitialSurveyCode;
  addSurveyCodes: AddSurveyCodes;
  addSingleSurveyCode: AddSingleSurveyCode;
  clearSurveyCodes: ClearSurveyCodes;
  initializeReportCallback: InitializeReportCallback;
  removeSurveyCode: RemoveSurveyCode;
  resetGraph: ResetGraph;
  setActiveTab: SetActiveTab;
  setBenchmarks: SetBenchmarks;
  setBenchmarksAvailable: SetBenchmarkAvailable;
  setClientScores: SetClientScores;
  setDashboardReady: SetDashboardReady;
  setDrilldown: SetDrilldown;
  setDrilldownData: SetDrilldownData;
  setLegend: SetLegend;
  toggleBenchmark: ToggleBenchmark;
  toggleDrilldown: ToggleDrilldown;
  toggleFilterDrawer: ToggleFilterDrawer;
  toggleRecommendations: ToggleRecommendations;
  unsetDashboardReady: UnsetDashboardReady;
  wipeState: WipeState;
}

type ACTIONS =
  | { type: "clear-survey-codes" }
  | { type: "remove-survey-code"; payload: { code: string } }
  | { type: "reset-graph" }
  | { type: "set-active-tab"; payload: { tab: TabsType } }
  | { type: "set-client-scores"; payload: { scores: Scores[] } }
  | { type: "set-benchmark-scores"; payload: { benchmarks: BenchmarkData[] } }
  | { type: "set-benchmark-available"; payload: { totalSubmissions: number } }
  | {
      type: "set-crumbs";
      payload: {
        type?: string;
        reset?: boolean;
        crumb: CrumbType;
        crumbs: CrumbType[];
      };
    }
  | { type: "set-drilldown-data"; payload: { data: DrilldownData[] } }
  | { type: "set-activity"; payload: { activity: Activities; status: Status } }
  | { type: "set-dimensions" }
  | { type: "set-drilldown"; payload: { subDimension: SubDimensions } }
  | { type: "set-graph-data"; payload: { tab: TabsType } }
  | { type: "set-legend"; payload: { legend: LegendItemType[] } }
  | { type: "set-single-survey-code"; payload: { code: string } }
  | {
      type: "set-survey-codes";
      payload: { initial?: boolean; codes: string[] };
    }
  | { type: "set-tab" }
  | { type: "toggle-benchmark"; payload: { state?: boolean } }
  | { type: "toggle-drilldown"; payload: { state?: boolean } }
  | { type: "toggle-filter-drawer"; payload: { state?: boolean } }
  | { type: "toggle-recommendations"; payload: { state?: boolean } }
  | { type: "wipe-state" };

export type DrilldownData = {
  answer: AnswerLabels;
  averageAnswer?: AnswerLabels;
  question: string;
  qcode: string;
};

export type Scores = {
  dimension: Dimensions;
  qcode: SubDimensions;
  score: number;
};

export type CurrentDrilldown = SubDimensions | undefined;

export type BenchmarkData = {
  dimension: Dimensions;
  qcode: SubDimensions;
  score: number;
};

export type Color = "teal" | "red" | "orange" | "softGray" | "purple" | "green";

export type LegendItemType = {
  label: string;
  color?: Color;
  style: Partial<React.CSSProperties>;
};

type DimensionChartData = {
  code: string;
  scores: Scores;
}[];

export interface State {
  activity: {
    [key in Activities]: Status;
  };
  activeTab: TabsType;
  benchmarkAvailable: boolean;
  benchmarkIsActive: boolean;
  benchmarkData: BenchmarkData[];
  clientScores: Scores[];
  currentDrilldown: CurrentDrilldown;
  crumbs: CrumbType[];
  dimensionChartData: DimensionChartData;
  drilldownData: DrilldownData[];
  drilldownIsActive: boolean;
  filterDrawerState: boolean;
  legend: LegendItemType[];
  recommendationsIsActive: boolean;
  surveyCodes: string[];
  surveyDataPage: Page | undefined;
}

function reducer(state: State, action: ACTIONS): State {
  if (action.type === "toggle-drilldown") {
    return {
      ...state,
      drilldownIsActive: !state.drilldownIsActive,
    };
  }

  if (action.type === "toggle-benchmark") {
    return {
      ...state,
      benchmarkIsActive: action.payload.state ?? !state.benchmarkIsActive,
    };
  }

  if (action.type === "set-activity") {
    return {
      ...state,
      activity: {
        ...state.activity,
        [action.payload.activity]: action.payload.status,
      },
    };
  }

  if (action.type === "set-drilldown") {
    if (action.payload.subDimension === state.currentDrilldown) {
      return {
        ...state,
        currentDrilldown: undefined,
        crumbs: state.crumbs.slice(0, state.crumbs.length - 1),
      };
    }

    return {
      ...state,
      currentDrilldown: action.payload.subDimension,
      drilldownIsActive: true,
      crumbs:
        state.currentDrilldown === undefined
          ? [...state.crumbs, action.payload.subDimension]
          : [
              ...state.crumbs.slice(0, state.crumbs.length - 1),
              action.payload.subDimension,
            ],
    };
  }

  if (action.type === "set-drilldown-data") {
    return {
      ...state,
      drilldownData: [...action.payload.data],
    };
  }

  if (action.type === "set-crumbs") {
    return {
      ...state,
      crumbs: [...state.crumbs, action.payload.crumb],
    };
  }

  if (action.type === "set-active-tab") {
    if (
      action.payload.tab === state.activeTab &&
      state.currentDrilldown === null
    )
      return state;

    return {
      ...state,
      activeTab: action.payload.tab,
      currentDrilldown: undefined,
      crumbs:
        action.payload.tab === "Overview" ? ["Overview"] : [action.payload.tab],
    };
  }

  if (action.type === "set-graph-data") {
    /**
     * When the user changes tab and we need to update the graph based on the new tab, only when it is to a dimension.
     */
    // const data = getScores(state.clientScores, action.payload.tab);

    if (action.payload.tab === "Overview") {
      return {
        ...state,
      };
    }

    return {
      ...state,
      // dimensionChartData: data,
    };
  }

  if (action.type === "toggle-recommendations") {
    return {
      ...state,
      recommendationsIsActive:
        action.payload.state ?? !state.recommendationsIsActive,
    };
  }

  if (action.type === "toggle-filter-drawer") {
    return {
      ...state,
      filterDrawerState: action.payload.state ?? !state.filterDrawerState,
    };
  }

  if (action.type === "set-client-scores") {
    return {
      ...state,
      clientScores: [...state.clientScores, ...action.payload.scores],
    };
  }

  if (action.type === "set-benchmark-scores") {
    return {
      ...state,
      benchmarkData: [...action.payload.benchmarks],
    };
  }

  if (action.type === "set-benchmark-available") {
    return {
      ...state,
      benchmarkAvailable: action.payload.totalSubmissions > 1 ? true : false,
    };
  }

  if (action.type === "set-survey-codes") {
    return {
      ...state,
      surveyCodes: [...state.surveyCodes, ...action.payload.codes],
    };
  }

  if (action.type === "remove-survey-code") {
    return {
      ...state,
      surveyCodes: state.surveyCodes.filter((s) => s !== action.payload.code),
    };
  }

  if (action.type === "clear-survey-codes") {
    return {
      ...state,
      surveyCodes: [],
    };
  }

  if (action.type === "reset-graph") {
    return {
      ...state,
      activeTab: "Overview",
      benchmarkIsActive: false,
      currentDrilldown: undefined,
    };
  }

  if (action.type === "set-single-survey-code") {
    return {
      ...state,
      surveyCodes: [action.payload.code],
    };
  }

  if (action.type === "set-legend") {
    if (isEqual(state.legend, action.payload.legend)) return state;

    return {
      ...state,
      legend: [...action.payload.legend],
    };
  }

  if (action.type === "wipe-state") {
    return initialState;
  }

  return state;
}

/**
 * Provides functionality for using the dashboard, with report handlers and overall state management.
 *
 * @param {function} addScores a function from useClients to add scores to that state
 * @param {boolean} isAuthenticated boolean to check if user is admin or not
 */

interface Props {
  addScores?: () => void;
  isAuthenticated?: boolean;
  passedInitialState?: State;
}

const initialState: State = {
  activity: {
    "dashboard-state": "isLoading",
  },
  activeTab: "Overview",
  benchmarkAvailable: false,
  benchmarkData: [],
  benchmarkIsActive: false,
  clientScores: [],
  currentDrilldown: undefined,
  crumbs: ["Overview"],
  dimensionChartData: [],
  drilldownData: [],
  drilldownIsActive: false,
  filterDrawerState: false,
  legend: [],
  recommendationsIsActive: false,
  surveyCodes: [],
  surveyDataPage: undefined,
};

function useReport(props: Props = {}) {
  const { addScores, isAuthenticated, passedInitialState } = props;

  const [state, dispatch] = useReducer(
    reducer,
    passedInitialState ?? initialState
  );

  const [error, setError] = useState<Error | null>(null);

  const toggleDrilldown: ToggleDrilldown = (toggleState = undefined) => {
    dispatch({
      type: "toggle-drilldown",
      payload: {
        state: toggleState,
      },
    });
  };

  const toggleBenchmark: ToggleBenchmark = (toggleState = undefined) => {
    dispatch({
      payload: {
        state: toggleState,
      },
      type: "toggle-benchmark",
    });
  };

  const setDrilldown: SetDrilldown = (subDimension) =>
    dispatch({
      payload: {
        subDimension,
      },
      type: "set-drilldown",
    });

  const setDashboardReady: SetDashboardReady = () => {
    dispatch({
      type: "set-activity",
      payload: {
        activity: "dashboard-state",
        status: "isComplete",
      },
    });
  };

  const unsetDashboardReady: UnsetDashboardReady = () => {
    dispatch({
      type: "set-activity",
      payload: {
        activity: "dashboard-state",
        status: "isLoading",
      },
    });
  };

  const toggleRecommendations: ToggleRecommendations = (
    toggleState = undefined
  ) => {
    dispatch({
      type: "toggle-recommendations",
      payload: {
        state: toggleState,
      },
    });
  };

  const toggleFilterDrawer: ToggleFilterDrawer = (toggleState = undefined) => {
    dispatch({
      payload: {
        state: toggleState,
      },
      type: "toggle-filter-drawer",
    });
  };

  const setActiveTab: SetActiveTab = (tab) => {
    dispatch({
      payload: {
        tab,
      },
      type: "set-active-tab",
    });
    dispatch({
      payload: {
        tab,
      },
      type: "set-graph-data",
    });
  };

  const addSurveyCodes: AddSurveyCodes = (codes = []) => {
    dispatch({
      payload: {
        codes,
      },
      type: "set-survey-codes",
    });
  };

  const addSingleSurveyCode: AddSingleSurveyCode = (code) => {
    dispatch({
      payload: {
        code,
      },
      type: "set-single-survey-code",
    });
  };

  const removeSurveyCode: RemoveSurveyCode = (code) => {
    dispatch({
      payload: {
        code,
      },
      type: "remove-survey-code",
    });
  };

  const addInitialSurveyCode: AddInitialSurveyCode = () => {
    dispatch({
      payload: {
        initial: true,
        codes: [],
      },
      type: "set-survey-codes",
    });
  };

  const setClientScores: SetClientScores = (scores) => {
    dispatch({
      type: "set-client-scores",
      payload: {
        scores,
      },
    });
  };

  const setBenchmarks: SetBenchmarks = (benchmarks) => {
    dispatch({
      type: "set-benchmark-scores",
      payload: {
        benchmarks,
      },
    });
  };

  const setBenchmarkAvailable: SetBenchmarkAvailable = (totalSubmissions) => {
    dispatch({
      type: "set-benchmark-available",
      payload: {
        totalSubmissions,
      },
    });
  };

  const setDrilldownData: SetDrilldownData = (data) => {
    dispatch({
      type: "set-drilldown-data",
      payload: {
        data,
      },
    });
  };

  const clearSurveyCodes: ClearSurveyCodes = () => {
    dispatch({
      type: "clear-survey-codes",
    });
  };

  const resetGraph: ResetGraph = () => {
    dispatch({
      type: "reset-graph",
    });
  };

  const setLegend: SetLegend = (legend) => {
    dispatch({
      payload: {
        legend,
      },
      type: "set-legend",
    });
  };

  const wipeState: WipeState = () => {
    dispatch({
      type: "wipe-state",
    });
  };

  /**
   * This function queries the Power BI visuals to retrieve the data within them, and then passes them to helper functions to parse the data.
   */
  const getClientsReport = useCallback(async (dataPage = null, visuals) => {
    if (!dataPage) return null;

    try {
      const returnedVisuals = returnVisualsForExporting(visuals);

      const { subDimensionsData } = await exportDataFromVisuals(
        returnedVisuals
      );
      const parsedSubDimensionsData = parseSubDimension(subDimensionsData);

      if (!checkSubDimensionsData(parsedSubDimensionsData)) {
        console.log(parsedSubDimensionsData);
        setError(new Error("Something went wrong"));
        return false;
      }

      setClientScores(parsedSubDimensionsData);
    } catch (e) {
      setError(new Error("Something went wrong"));
      return false;
    }
  }, []);

  /**
   * Extract benchmark data from visuals and parse it.
   */
  const getBenchmarkResults = useCallback(async (dataPage = null, visuals) => {
    if (!dataPage) return null;
    try {
      const {
        mostCommonAnswers,
        clientAnswers,
        subDimensionBenchmarks,
        totalSubmissions,
      } = returnVisualsForExporting(visuals);

      const {
        subDimensionBenchmarksData,
        mostCommonAnswersData,
        clientAnswersData,
        totalSubmissionsData,
      } = await exportDataFromVisuals({
        mostCommonAnswers,
        clientAnswers,
        subDimensionBenchmarks,
        totalSubmissions,
      });

      const parsedTotalSubmissionsData =
        parseTotalSubmissions(totalSubmissionsData);

      const parsedClientsAnswersData = parseClientsAnswers(clientAnswersData);

      const parsedMostCommonAnswersData = parseMostCommmonAnswers(
        mostCommonAnswersData
      );

      const parsedSubDimensionBenchmarksData = parseSubDimensionBenchmarks(
        subDimensionBenchmarksData,
        parsedTotalSubmissionsData
      );

      const mergedDrilldownData = mergeAnswersData(
        parsedMostCommonAnswersData,
        parsedClientsAnswersData
      );

      if (
        mergedDrilldownData.length === 0 &&
        parsedSubDimensionBenchmarksData.length > 0
      ) {
        setError(new Error("No report available"));
        return false;
      }

      /**
       * Perform checks on parsed data to ensure what has been parsed is acceptable.
       */
      if (
        !checkBenchmarkData(parsedSubDimensionBenchmarksData) ||
        !checkDrilldownData(mergedDrilldownData)
      ) {
        setError(new Error("Something went wrong"));
        return false;
      }

      setBenchmarks(parsedSubDimensionBenchmarksData);
      setDrilldownData(mergedDrilldownData);
      setBenchmarkAvailable(parsedTotalSubmissionsData);

      return true;
    } catch (e) {
      console.log(e);
      setError(new Error("Something went wrong"));
      return false;
    }
  }, []);

  /**
   * Called when Power BI report is loaded to generate results from visuals.
   */
  const initializeReportCallback: InitializeReportCallback = useCallback(
    async (passedReport) => {
      if (!passedReport) return;

      if (!isEmpty(state.surveyDataPage)) return;

      try {
        const pages = await passedReport.getPages();

        const surveyDataPage = pages[0];

        const visuals = await surveyDataPage?.getVisuals();

        if (
          pulledVisualsIsIncorrect(visuals) === true ||
          visuals === undefined
        ) {
          setError(new Error("Something went wrong"));
          return;
        }

        /**
         * Export the data and store it in the state.
         */
        await getBenchmarkResults(surveyDataPage, visuals);
        await getClientsReport(surveyDataPage, visuals);

        setDashboardReady();

        return;
      } catch (e) {
        setError(new Error("Something went wrong"));
        return;
      }
    },
    [
      state.surveyDataPage,
      getClientsReport,
      getBenchmarkResults,
      addScores,
      isAuthenticated,
    ]
  );

  return {
    adminHandlers: {
      addSurveyCodes,
      addInitialSurveyCode,
      clearSurveyCodes,
      removeSurveyCode,
      resetGraph,
      toggleFilterDrawer,
      unsetDashboardReady,
    },
    clientHandlers: {
      addSingleSurveyCode,
      toggleRecommendations,
    },
    error,
    genericHandlers: {
      initializeReportCallback,
      setActiveTab,
      setDrilldown,
      setDashboardReady,
      setLegend,
      toggleBenchmark,
      toggleDrilldown,
      wipeState,
    },
    state,
  };
}

export default useReport;

export interface NewVisualDescriptor extends VisualDescriptor {
  title: PowerBiVisualTitles;
}

export type VisualsToExport = {
  mostCommonAnswers: NewVisualDescriptor;
  clientAnswers: NewVisualDescriptor;
  subDimensionBenchmarks: NewVisualDescriptor;
  subDimensionScores?: NewVisualDescriptor;
  totalSubmissions: NewVisualDescriptor;
};

type ExportedDataFromVisuals = {
  mostCommonAnswersData: string;
  clientAnswersData: string;
  subDimensionBenchmarksData: string;
  subDimensionsData: string;
  totalSubmissionsData: string;
};

async function exportDataFromVisuals({
  mostCommonAnswers,
  clientAnswers,
  subDimensionBenchmarks,
  subDimensionScores,
  totalSubmissions,
}: VisualsToExport): Promise<ExportedDataFromVisuals> {
  let mostCommonAnswersData = "";
  let clientAnswersData = "";
  let subDimensionBenchmarksData = "";
  let subDimensionsData = "";
  let totalSubmissionsData = "";

  await subDimensionBenchmarks
    ?.exportData(models.ExportDataType.Summarized)
    .then((res) => (subDimensionBenchmarksData = res.data))
    .catch(() => false);

  await subDimensionScores
    ?.exportData(models.ExportDataType.Summarized)
    .then((res) => (subDimensionsData = res.data))
    .catch(() => false);

  await mostCommonAnswers
    ?.exportData(models.ExportDataType.Summarized)
    .then((res) => (mostCommonAnswersData = res.data))
    .catch(() => false);

  await clientAnswers
    ?.exportData(models.ExportDataType.Summarized)
    .then((res) => (clientAnswersData = res.data))
    .catch(() => false);

  await totalSubmissions
    ?.exportData(models.ExportDataType.Summarized)
    .then((res) => (totalSubmissionsData = res.data))
    .catch(() => false);

  return {
    mostCommonAnswersData,
    clientAnswersData,
    subDimensionBenchmarksData,
    subDimensionsData,
    totalSubmissionsData,
  };
}

type DimensionBenchmarks = {
  dimension: Dimensions;
  score: number;
};

export const reduceBenchmarksToDimensionBenchmarks = (
  benchmarks: BenchmarkData[]
) => {
  return benchmarks.reduce((acc: DimensionBenchmarks[], curr) => {
    const index = acc.findIndex((row) => row.dimension === curr.dimension);

    if (index >= 0) {
      const copiedAcc = [...acc];
      copiedAcc[index].score += curr.score;
      return copiedAcc;
    }

    return [
      ...acc,
      {
        dimension: curr.dimension,
        score: curr.score,
      },
    ];
  }, []);
};

export type ReturnedResults = {
  dimension: Dimensions;
  benchmark: number;
  score: number;
  qcode?: SubDimensions;
};

export const getDimensionScores = (
  scores: Scores[],
  benchmarks: BenchmarkData[]
) => {
  const dimensionBenchmarks = reduceBenchmarksToDimensionBenchmarks(benchmarks);
  return scores.reduce((acc: ReturnedResults[], curr) => {
    const index = acc.findIndex((row) => row.dimension === curr.dimension);

    if (index >= 0) {
      const copiedAcc = [...acc];
      copiedAcc[index].score += curr.score;
      return copiedAcc;
    }

    return [
      ...acc,
      {
        dimension: curr.dimension,
        benchmark: dimensionBenchmarks.find(
          (b) => b.dimension === curr.dimension
        )!.score,
        score: curr.score,
      },
    ];
  }, []);
};

export type SubDimensionResult = {
  qcode: SubDimensions;
  dimension: Dimensions;
  benchmark: number;
  score: number;
};

export const filterScoresForSelection = (
  scores: Scores[],
  benchmarks: BenchmarkData[],
  activeTab: TabsType
) => {
  return scores
    .filter((score) => score.dimension === activeTab)
    .map<ReturnedResults>((score) => ({
      ...score,
      benchmark: benchmarks.find(
        (benchmark) => benchmark.qcode === score.qcode
      )!.score,
    }));
};

export const getDimensionTranslationKey = (
  input: Dimensions
): DimensionsTranslationKeys => {
  return input.split(" ").join("_") as DimensionsTranslationKeys;
};

export const getSubDimensionTranslationKey = (
  input: SubDimensions
): SubDimensionsTranslationKeys => {
  return input
    .split(" ")
    .join("_")
    .replace(",", "") as SubDimensionsTranslationKeys;
};
