import DMA_LOGO from "assets/DMA-Icon.svg";
import Icon from "assets/icons/Icon";
import {
  getDimensionTranslationKey,
  SetActiveTab,
  TABS,
  TabsType,
  ToggleDrilldown,
} from "hooks/useReport";
import getDimensionIcon from "utils/getDimensionIcon";
import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiChevronDoubleDown, HiChevronDoubleUp } from "react-icons/hi";
import { MdMoreHoriz } from "react-icons/md";
import ActionButton from "../../ActionButton";
import ControlWrapper from "../../ControlWrapper/ControlWrapper";
import styles from "./DimensionsBar.module.scss";

interface DimensionsBarProps {
  activeTab: TabsType;
  drilldownIsActive: boolean;
  setActiveTab: SetActiveTab;
  toggleDrilldown: ToggleDrilldown;
  isLoading: boolean;
}

function DimensionsBar({
  activeTab,
  drilldownIsActive,
  setActiveTab,
  toggleDrilldown,
  isLoading,
}: DimensionsBarProps) {
  const { t, ready } = useTranslation();
  const [scrollPosition, setScrollPosition] = useState({
    top: true,
    bottom: false,
  });
  const scrollableRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = (e: React.WheelEvent<HTMLDivElement>) => {
    const top = e.currentTarget.scrollTop === 0;
    const bottom =
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
      e.currentTarget.clientHeight;

    setScrollPosition({
      top,
      bottom,
    });
  };

  // Reset it's scrollTop to 0 on mount to prevent any UI bugs
  useEffect(() => {
    scrollableRef.current!.scrollTop = 0;
  }, []);

  if (!ready) return null;
  return (
    <ControlWrapper className={styles.bar}>
      <div className={styles.bar_container}>
        <div className={styles.bar_button_list}>
          <ScrollIndicators isDisabled={scrollPosition.top} orientation="up" />
          <div
            className={cx(styles.bar_button_rows, {
              [styles.prevent_scroll]: isLoading,
            })}
            ref={scrollableRef}
            onScroll={handleScroll}
          >
            {isLoading
              ? [...Array(7)].map((_, index) => (
                  <ActionButton
                    label="Loading"
                    key={index}
                    className={styles.bar_button}
                    isDisabled
                    popperEnabled={false}
                  />
                ))
              : Object.values(TABS)?.map((tab) => (
                  <ActionButton
                    label={
                      tab === "Overview"
                        ? t("controls.overview")
                        : t(`dimensions.${getDimensionTranslationKey(tab)}`)
                    }
                    key={tab}
                    popperPlacement="right"
                    onClick={() => setActiveTab(tab)}
                    type="button"
                    className={cx(styles.bar_button, {
                      [styles.bar_button_home]: tab === "Overview",
                      [styles.bar_button_teal]:
                        tab === "Sustainable Operating Model",
                      [styles.bar_button_green]: tab === "Customer",
                      [styles.bar_button_red]: tab === "Culture",
                      [styles.bar_button_orange]: tab === "Capabilities",
                      [styles.bar_button_gray]: tab === "Data",
                      [styles.bar_button_purple]: tab === "Technology",
                      [styles.active]: activeTab === tab,
                    })}
                  >
                    <ButtonContent tab={tab} />
                  </ActionButton>
                ))}
          </div>
          <ScrollIndicators
            isDisabled={scrollPosition.bottom}
            orientation="down"
          />
          <ActionButton
            popperEnabled={drilldownIsActive}
            onClick={() => toggleDrilldown()}
            label={t("controls.drilldown")}
            popperPlacement="right"
            isDisabled={isLoading || activeTab === "Overview"}
            className={cx(styles.bar_button, styles.bar_button_drilldown, {
              [styles.bar_button_drilldown_active]: drilldownIsActive,
              [styles.bar_button_drilldown_loading]: isLoading,
            })}
          >
            {isLoading ? null : <MdMoreHoriz />}
          </ActionButton>
        </div>
      </div>
    </ControlWrapper>
  );
}

export default DimensionsBar;

interface ScrollIndicatorsProps {
  orientation: "up" | "down";
  isDisabled: boolean;
}

const ScrollIndicators = ({
  orientation,
  isDisabled,
}: ScrollIndicatorsProps) => (
  <div
    className={cx(styles.bar_scroll_indicator, {
      [styles.bar_scroll_indicator_disabled]: isDisabled,
    })}
  >
    {orientation === "up" ? <HiChevronDoubleUp /> : <HiChevronDoubleDown />}
  </div>
);

const ButtonContent = ({ tab }: { tab: TabsType }) => {
  if (tab === "Overview") {
    return (
      <img
        src={DMA_LOGO}
        alt="Digital Maturity Assessment Logo"
        className={styles.bar_button_icon}
      />
    );
  }

  return <Icon icon={getDimensionIcon(tab)} />;
};
