import Loader from "components/generic/Loader/Loader";
import { Breakpoints } from "hooks/common/useBreakpoints";
import useRecommendations from "hooks/useRecommendations";
import {
  getDimensionScores,
  State,
  TabsType,
  ToggleRecommendations,
} from "hooks/useReport";
import cx from "classnames";
import React, { useEffect, useState } from "react";
import Drawer from "../Drawer";
import Actions from "./Actions";
import Body from "./Body";
import Disclaimer from "./Disclaimer";
import Header from "./Header";
import styles from "./Recommendations.module.scss";
import Tabs from "./Tabs";

interface RecommendationsProps {
  benchmarkAvailable: boolean;
  benchmarks: State["benchmarkData"];
  isLoading: boolean;
  isActive: boolean;
  handleToggle: ToggleRecommendations;
  clientScores: State["clientScores"];
  breakpoints: Breakpoints;
}

function Recommendations({
  benchmarkAvailable,
  benchmarks,
  isLoading,
  isActive,
  handleToggle,
  clientScores,
  breakpoints,
}: RecommendationsProps) {
  const [tab, setTab] = useState<TabsType>("Overview");
  const [isMaximized, setIsMaximized] = useState(false);

  const handleSetTab = (newTab: TabsType) => {
    if (tab === newTab) return;

    setTab(newTab);
  };

  useEffect(() => {
    if (isActive) return;

    const timeout = setTimeout(() => {
      setTab("Overview");
      setIsMaximized(false);
    }, 250);

    return () => clearTimeout(timeout);
  }, [isActive]);

  const scores = getDimensionScores(clientScores, benchmarks);

  const { scoresWithRecommendations, totalScore } = useRecommendations({
    dimensionScores: scores,
    benchmarks,
  });

  if (isLoading) return null;

  if (
    breakpoints.active === "sm" ||
    (breakpoints.active === "md" && breakpoints.isLandscape)
  ) {
    return (
      <RecommendationsMobile isLoading={isLoading}>
        <Header />
        <Body
          totalScore={totalScore}
          scoresWithRecommendations={scoresWithRecommendations}
          handleSetTab={handleSetTab}
          tab={tab}
          benchmarkAvailable={benchmarkAvailable}
        />
        <Tabs tab={tab} setTab={handleSetTab} />
        <Disclaimer isLoading={isLoading} />
      </RecommendationsMobile>
    );
  }

  return (
    <RecommendationsDesktop
      isActive={isActive}
      handleToggle={handleToggle}
      isMaximized={isMaximized}
    >
      <Header />
      <Actions
        isMaximized={isMaximized}
        onClose={() => handleToggle()}
        onResize={() => setIsMaximized((prev) => !prev)}
      />
      <Tabs tab={tab} setTab={handleSetTab} />
      <Body
        totalScore={totalScore}
        scoresWithRecommendations={scoresWithRecommendations}
        handleSetTab={handleSetTab}
        tab={tab}
        benchmarkAvailable={benchmarkAvailable}
      />
    </RecommendationsDesktop>
  );
}

export default Recommendations;

interface RecommendationsDesktopProps
  extends Pick<RecommendationsProps, "isActive" | "handleToggle"> {
  isMaximized: boolean;
  children: React.ReactNode;
}

const RecommendationsDesktop = ({
  isActive,
  handleToggle,
  isMaximized,
  children,
}: RecommendationsDesktopProps) => (
  <Drawer
    isOpen={isActive}
    onClickOutside={handleToggle}
    className={cx(styles.recommendations, {
      [styles.maximized]: isMaximized,
    })}
  >
    {children}
  </Drawer>
);

interface RecommendationsMobileProps {
  isLoading: boolean;
  children: React.ReactNode;
}

const RecommendationsMobile = ({
  isLoading,
  children,
}: RecommendationsMobileProps) => (
  <Loader
    isLoading={isLoading}
    loadingText="Loading your recommendations..."
    className={styles.recommendations_loader}
  >
    {children}
  </Loader>
);
