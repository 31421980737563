import { useState, useEffect } from "react";
import {
  BenchmarkData,
  LegendItemType,
  Scores,
  SetLegend,
  TabsType,
} from "../../../hooks/useReport";
import { getDimensionColour } from "../../../utils/reportHelpers";
import dashedLines from "../../../assets/dashed-line.svg";
import { useTranslation } from "react-i18next";
import { getSubDimensionTranslationKey } from "./../../../hooks/useReport";
import defaultOptions from "./options";

function sortAlphabeticallyByDimension(a: Partial<Scores>, b: Partial<Scores>) {
  if (a.dimension! < b.dimension!) return -1;
  if (a.dimension! > b.dimension!) return 1;
  return 0;
}

function sortAlphabeticallyBySubDimension(
  a: Partial<Scores>,
  b: Partial<Scores>
) {
  if (a.qcode! < b.qcode!) return -1;
  if (a.qcode! > b.qcode!) return 1;
  return 0;
}

interface UseDimensionsGraphProps {
  activeTab: TabsType;
  scores: Scores[];
  benchmarks: BenchmarkData[];
  benchmarkActive: boolean;
  setLegend: SetLegend;
  surveyCodes: string[];
  isMulti?: boolean;
}

export default function useDimensionsGraph({
  activeTab,
  scores,
  benchmarks,
  benchmarkActive,
  setLegend,
}: UseDimensionsGraphProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [options, setOptions] = useState(defaultOptions);

  useEffect(() => {
    if (language === "ja") {
      setOptions((prev) => ({
        ...prev,
        scales: {
          ...prev.scales,
          r: {
            ...prev.scales?.r,
            pointLabels: {
              ...prev.scales?.r?.pointLabels,
              font: {
                ...prev.scales?.r?.pointLabels?.font,
                family: "Noto Sans JP",
                weight: "regular",
              },
            },
          },
        },
      }));
    } else {
      setOptions((prev) => ({
        ...prev,
        scales: {
          ...prev.scales,
          r: {
            ...prev.scales?.r,
            pointLabels: {
              ...prev?.scales?.r?.pointLabels,
              font: {
                ...prev?.scales?.r?.pointLabels?.font,
                family: "GT Walsheim Pro",
                weight: "regular",
              },
            },
          },
        },
      }));
    }
  }, [language]);

  let backgroundColor;
  let borderColor;
  let datasets;
  let scoreData;
  let benchmarkData;
  let labels;
  let legends: LegendItemType[];

  enum Sorted {
    Capabilities,
    Culture,
    Customer,
    Data,
    "Sustainable Operating Model",
    Technology,
  }

  if (activeTab === "Overview") {
    backgroundColor = "rgba(35, 20, 56, 0.4)";
    borderColor = "rgba(35, 20, 56, 1)";

    const scoresSortedByDimensions = scores.sort(sortAlphabeticallyByDimension);
    const benchmarksSortedByDimensions = benchmarks.sort(
      sortAlphabeticallyByDimension
    );

    scoreData = scoresSortedByDimensions.reduce(
      (acc, curr) => {
        const copiedAcc = [...acc];
        const indexInReturnedArray = Sorted[curr.dimension];
        copiedAcc[indexInReturnedArray] =
          copiedAcc[indexInReturnedArray] + curr.score;
        return copiedAcc;
      },
      [0, 0, 0, 0, 0, 0]
    );

    benchmarkData = benchmarksSortedByDimensions.reduce(
      (acc, curr) => {
        const copiedAcc = [...acc];
        const indexInReturnedArray = Sorted[curr.dimension];
        copiedAcc[indexInReturnedArray] =
          copiedAcc[indexInReturnedArray] + (curr.score as number);
        return copiedAcc;
      },
      [0, 0, 0, 0, 0, 0]
    );

    labels = [
      t("dimensions.Capabilities"),
      t("dimensions.Culture"),
      t("dimensions.Customer"),
      t("dimensions.Data"),
      t("dimensions.Sustainable_Operating_Model"),
      t("dimensions.Technology"),
    ];
  } else {
    [backgroundColor, borderColor] = getDimensionColour(activeTab);

    const sortedScores = scores
      .filter((score) => score.dimension === activeTab)
      .sort(sortAlphabeticallyBySubDimension);

    const benchmarkScores = benchmarks
      .filter((benchmark) => benchmark.dimension === activeTab)
      .sort(sortAlphabeticallyBySubDimension);

    scoreData = sortedScores.map((score) => score.score);
    benchmarkData = benchmarkScores.map((benchmark) => benchmark.score);
    labels = sortedScores.map((score) =>
      t(`subDimensions.${getSubDimensionTranslationKey(score.qcode)}`)
    );
  }

  const your_score_text = t("dimensions.your_score");
  const benchmark_text = t("dimensions.benchmark");
  if (benchmarkActive) {
    datasets = [
      {
        data: benchmarkData,
        label: benchmark_text,
        pointHitRadius: 8,
        pointRadius: 4,
        backgroundColor: "transparent",
        borderColor: "rgba(35, 20, 56, 1)",
        borderDash: [2, 6],
        pointBackgroundColor: "rgba(35, 20, 56, 1)",
      },
      {
        data: scoreData,
        label: your_score_text,
        backgroundColor,
        borderColor,
        pointBackgroundColor: borderColor,
        pointHitRadius: 8,
        pointRadius: 4,
      },
    ];
  } else {
    datasets = [
      {
        data: scoreData,
        label: your_score_text,
        backgroundColor,
        borderColor,
        pointBackgroundColor: borderColor,
        pointHitRadius: 8,
        pointRadius: 4,
      },
    ];
  }

  const data = {
    labels,
    datasets,
  };

  // eslint-disable-next-line prefer-const
  legends = [
    {
      label: your_score_text,
      style: {
        backgroundColor,
        border: `2px solid ${borderColor}`,
      },
    },
  ];

  useEffect(() => {
    setLegend(
      benchmarkActive
        ? [
            {
              label: benchmark_text,
              style: {
                backgroundColor: "transparent",
                backgroundImage: `url(${dashedLines})`,
                backgroundPosition: "center",
                backgroundSize: "contain",
                borderColor: "rgba(35, 20, 56, 1)",
              },
            },
            ...legends,
          ]
        : legends
    );
  }, [setLegend, benchmarkActive, data]);

  useEffect(() => {
    setOptions((prev) => ({
      ...prev,
      scales: {
        ...prev.scales,
        r: {
          ...prev?.scales?.r,
          max: activeTab === "Overview" ? 80 : 20,
          ticks: {
            ...prev?.scales?.r?.ticks,
            stepSize: activeTab === "Overview" ? 20 : 4,
          },
        },
      },
    }));
  }, [activeTab]);

  return [data, options] as const;
}
