import React, { useCallback, useMemo } from "react";
import { node, string, bool, oneOf } from "prop-types";
import cx from "classnames";
import styles from "./FormControl.module.scss";
import createContext from "../../../utils/createContext";
import { dataAttributes } from "../../../utils/dom";

const [FormControlContext, useFormControlContext] = createContext({
  name: "FormControl",
});

function useFormControlProvider(props) {
  const { id, isDisabled, isInvalid, isRequired, ...restProps } = props;

  const getLabelProps = useCallback(
    (passedProps = {}) => ({
      ...passedProps,
      "data-disabled": dataAttributes(isDisabled),
      "data-invalid": dataAttributes(isInvalid),
      htmlFor: passedProps.htmlFor ?? id,
    }),
    [id, isDisabled, isInvalid]
  );

  const getHelperCaptionProps = useCallback(
    (passedProps = {}) => ({
      ...passedProps,
      "data-disabled": dataAttributes(isDisabled),
      "data-invalid": dataAttributes(isInvalid),
    }),
    [isDisabled, isInvalid]
  );

  const getErrorCaptionProps = useCallback(
    (passedProps = {}) => ({
      ...passedProps,
      "aria-invalid": true,
      "aria-live": "polite",
    }),
    []
  );

  return {
    getErrorCaptionProps,
    getHelperCaptionProps,
    getLabelProps,
    id,
    isDisabled: !!isDisabled,
    isInvalid: !!isInvalid,
    isRequired: !!isRequired,
    ...restProps,
  };
}

/**
 * A component that controls children labels, inputs and helper
 * captions. Props such as `isInvalid` and `isDisabled` can be
 * passed down through context.
 */

function FormControl(props) {
  const { children, className, ...rest } = props;
  const classNames = cx(styles.control, className);

  const { ...context } = useFormControlProvider(rest);

  const contextValue = useMemo(() => context, [context]);

  return (
    <FormControlContext value={contextValue}>
      <div className={classNames}>{children}</div>
    </FormControlContext>
  );
}

export default FormControl;

export { useFormControlContext };

FormControl.propTypes = {
  /** The elements within to be grouped together */
  children: node,
  /** For CSS customisation */
  className: string,
  /** The id to be passed to the label and input */
  id: string.isRequired,
  /** If true, the input will be disabled */
  isDisabled: bool,
  /** If true, the input will be invalid */
  isInvalid: bool,
  /** If true, the input will be read only */
  isReadOnly: bool,
  /** If true, the input will be marked as required with an asterisk */
  isRequired: bool,
  /** If true, the input will be highlighted to show successful input */
  isValid: bool,
  /** The name of the input, which will be submitted with the form */
  name: string.isRequired,
  /** Set the size of the input and labels */
  size: oneOf(["md", "sm", "xs"]),
};
