import Icon from "assets/icons/Icon";
import Text from "components/generic/Text/Text";
import {
  CurrentDrilldown,
  getSubDimensionTranslationKey,
  SetDrilldown,
  TabsType,
} from "hooks/useReport";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { MdQuestionAnswer } from "react-icons/md";
import { Dimensions, SubDimensions } from "types/dimensions";
import ControlWrapper from "../../ControlWrapper";
import data from "./data.json";
import styles from "./DimensionsDrilldown.module.scss";

interface DimensionDrilldownProps {
  activeTab: TabsType;
  currentDrilldown: CurrentDrilldown;
  setDrilldown: SetDrilldown;
}

function DimensionDrilldown({
  activeTab,
  currentDrilldown,
  setDrilldown,
}: DimensionDrilldownProps) {
  if (activeTab === "Overview") return null;
  const { t } = useTranslation();
  return (
    <ControlWrapper className={styles.drilldown}>
      <Text variant="bodyLarge" className={styles.drilldown_title}>
        {t("controls.drilldown")}
        <MdQuestionAnswer />
      </Text>
      <div className={styles.drilldown_list}>
        <div className={styles.drilldown_sub_dimension}>
          {data.dimensions[activeTab].map((subDimension) => (
            <DrilldownRow
              key={subDimension}
              currentDrilldown={currentDrilldown}
              subDimension={subDimension as SubDimensions}
              activeTab={activeTab}
              setDrilldown={setDrilldown}
            />
          ))}
        </div>
      </div>
    </ControlWrapper>
  );
}

export default DimensionDrilldown;

interface DrilldownRowProps {
  currentDrilldown: CurrentDrilldown;
  subDimension: SubDimensions;
  activeTab: Dimensions;
  setDrilldown: SetDrilldown;
}

const DrilldownRow = ({
  currentDrilldown,
  subDimension,
  activeTab,
  setDrilldown,
  ...rest
}: DrilldownRowProps) => {
  const { t } = useTranslation();
  return (
    <button
      type="button"
      className={cx(styles.drilldown_row, {
        [styles.drilldown_active]: currentDrilldown === subDimension,
        [styles.drilldown_row_teal]:
          activeTab === "Sustainable Operating Model",
        [styles.drilldown_row_purple]: activeTab === "Technology",
        [styles.drilldown_row_orange]: activeTab === "Capabilities",
        [styles.drilldown_row_red]: activeTab === "Culture",
        [styles.drilldown_row_softGray]: activeTab === "Data",
        [styles.drilldown_row_green]: activeTab === "Customer",
      })}
      onClick={() => setDrilldown(subDimension)}
      {...rest}
    >
      <Text>
        {t(`subDimensions.${getSubDimensionTranslationKey(subDimension)}`).join(
          " "
        )}
      </Text>
      <div
        className={cx(styles.drilldown_row_icon, {
          [styles.drilldown_row_icon_active]: currentDrilldown === subDimension,
          [styles.drilldown_row_icon_teal]:
            activeTab === "Sustainable Operating Model",
          [styles.drilldown_row_icon_purple]: activeTab === "Technology",
          [styles.drilldown_row_icon_orange]: activeTab === "Capabilities",
          [styles.drilldown_row_icon_red]: activeTab === "Culture",
          [styles.drilldown_row_icon_softGray]: activeTab === "Data",
          [styles.drilldown_row_icon_green]: activeTab === "Customer",
        })}
      >
        {currentDrilldown === subDimension ? (
          <Icon
            icon="GtCheckmark"
            className={styles.drilldown_row_icon_check}
          />
        ) : null}
      </div>
    </button>
  );
};
