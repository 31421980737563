import React, { useMemo } from "react";
import cx from "classnames";
import styles from "./InputGroup.module.scss";
import createContext from "../../../../utils/createContext";

const [InputGroupContext, useInputGroupContext] = createContext({
  name: "InputGroup",
});

/**
 * Returns a component that wraps `Input`, `InputLeftElement`, `InputRightElement`, `InputLeftAddon`, `InputRightAddon` components and provides context so the `Input` component is aware of which of the previously mentioned components are active. This allows it to add extra styles.
 */

function InputGroup(props) {
  const { children, className } = props;
  // Map the children to a regular array, and then reduce it down to an object which will be used as the value for the context. This object will contain information about which components are in the `InputGroup` component.
  const childrenContext = React.Children.map(children, (child) => child).reduce(
    (acc, curr) => {
      if (curr.type.name === "Input") {
        acc.input.active = true;
      }

      if (curr.type.name === "InputLeftAddon") {
        acc.leftAddon.active = true;
      }

      if (curr.type.name === "InputRightAddon") {
        acc.rightAddon.active = true;
      }

      if (curr.type.name === "InputLeftElement") {
        acc.leftElement.active = true;
        acc.leftElement.size = curr.props.size ?? null;
      }

      if (curr.type.name === "InputRightElement") {
        acc.rightElement.active = true;
        acc.rightElement.size = curr.props.size ?? null;
      }
      return acc;
    },
    // Default state
    {
      input: {
        active: false,
      },
      leftAddon: {
        active: false,
      },
      leftElement: {
        active: false,
        size: "",
      },
      rightAddon: {
        active: false,
      },
      rightElement: {
        active: false,
        size: "",
      },
    }
  );
  const classNames = cx(
    styles.group,
    {
      [styles.hasAddon]:
        childrenContext.leftAddon.active || childrenContext.rightAddon.active,
    },
    className
  );

  const context = useMemo(() => childrenContext, [childrenContext]);

  return (
    <InputGroupContext value={context}>
      <div className={classNames}>{children}</div>
    </InputGroupContext>
  );
}

export default InputGroup;

export { useInputGroupContext };
