import { ScoresWithRecommendations } from "hooks/useRecommendations";
import { TabsType } from "hooks/useReport";
import React, { useEffect, useRef } from "react";
import { Dimensions } from "types/dimensions";
import styles from "./Body.module.scss";
import Dimension from "./Dimension";
import Overview from "./Overview";
import { useTranslation } from "react-i18next";
import { getRankLabelByScore } from "./../../../utils/function";

export interface BodyProps {
  benchmarkAvailable: boolean;
  totalScore: number;
  scoresWithRecommendations: ScoresWithRecommendations[];
  handleSetTab: (newTab: Dimensions) => void;
  tab: TabsType;
}

function Body({
  benchmarkAvailable,
  totalScore,
  scoresWithRecommendations,
  handleSetTab,
  tab,
}: BodyProps) {
  const bodyRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const rank = getRankLabelByScore(totalScore);

  useEffect(() => {
    if (bodyRef.current === null) return;
    bodyRef.current.scrollTop = 0;
  }, [tab]);

  return (
    <div className={styles.body} ref={bodyRef}>
      <div className={styles.body_wrapper}>
        {tab === "Overview" ? (
          <Overview
            intro={t(`recommendationsIntro.${rank}`)}
            handleSetTab={handleSetTab}
            totalScore={totalScore}
            scoresWithRecommendations={scoresWithRecommendations}
            benchmarkAvailable={benchmarkAvailable}
          />
        ) : (
          <Dimension
            tab={tab}
            scoresWithRecommendations={scoresWithRecommendations}
            benchmarkAvailable={benchmarkAvailable}
          />
        )}
      </div>
    </div>
  );
}

export default Body;
