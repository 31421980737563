import React from "react";
import cx from "classnames";
import styles from "./Loader.module.scss";
import Spinner from "../Spinner/Spinner";
import Text from "../Text/Text";

interface LoaderProps {
  isLoading: boolean;
  disableTransition?: boolean;
  loadingText?: string;
  children: React.ReactNode;
  className?: string;
}

/**
 * Returns a loader state for the PowerBI Dashboard. When the report is rendered, it fires a callback  to set the isReady prop to true. The PowerBI component needs to be rendered in the DOM to load so we can only hide it behind a CSS animation, rather than mounting it.
 */

function Loader({
  isLoading,
  disableTransition = false,
  loadingText = "Loading...",
  children,
  className,
}: LoaderProps) {
  return (
    <div className={cx(styles.loader_container, className)}>
      {isLoading ? (
        <div className={styles.spinner_container}>
          <Spinner color="purple" size="lg" />
          <Text className={styles.spinner_container_message}>
            {loadingText}
          </Text>
        </div>
      ) : null}
      <div
        className={cx(styles.main_content, {
          [styles.active]: !isLoading,
          [styles.disabled_transition]: disableTransition,
        })}
      >
        {children}
      </div>
    </div>
  );
}

export default Loader;
