import { useState, useEffect } from "react";
import { usePopper } from "react-popper";
import { Placement } from "@popperjs/core/lib/enums";

export interface UseTooltipProps {
  popperPlacement?: Placement;
  popperEnabled?: boolean;
  popperOffset?: [number, number];
  visibleProp?: boolean;
}

function useTooltip({
  popperPlacement = "left",
  popperEnabled = true,
  popperOffset = [0, 10],
  visibleProp,
}: UseTooltipProps) {
  const [visible, setVisible] = useState(visibleProp ?? false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  );
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const { styles: popperStyles, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      modifiers: [
        { name: "arrow", options: { element: arrowElement } },
        {
          enabled: true,
          name: "offset",
          options: {
            offset: popperOffset,
          },
        },
      ],
      placement: popperPlacement,
    }
  );

  useEffect(() => {
    if (popperEnabled || !visible) return;

    return setVisible(false);
  }, [popperEnabled, visible]);

  return {
    attributes,
    popperElement,
    popperStyles,
    referenceElement,
    setArrowElement,
    setPopperElement,
    setReferenceElement,
    setVisible,
    visible,
  };
}

export default useTooltip;
