import { UserConfig } from "App";
import AdminDashboard from "components/AdminDashboard";
import Dashboard from "components/Dashboard";
import { useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import ResultsDashboard from "../ResultsDashboard";

export const AdminRoutes = ({ accessToken, roles }: UserConfig) => {
  const history = useHistory();

  useEffect(() => {
    if (roles && !roles?.find((role) => role === "admin")) {
      history.replace("/forbidden");
      return;
    }
  }, [roles]);

  if (!accessToken) return null;

  return (
    <Switch>
      <AdminDashboard accessToken={accessToken} />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export const ClientRoutes = ({ accessToken }: UserConfig) => {
  const { path } = useRouteMatch();

  if (!accessToken) return null;

  return (
    <Switch>
      <Route path={`${path}`} exact>
        <Dashboard>
          <ResultsDashboard accessToken={accessToken} />
        </Dashboard>
      </Route>
    </Switch>
  );
};
