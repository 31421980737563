import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";
import Modal from "components/generic/Modal/Modal";
import useOnClickOutside from "components/generic/hooks/useOnClickOutside";
import DrawerBody from "./DrawerBody";
import styles from "./Drawer.module.scss";

interface DrawerProps {
  children: React.ReactNode;
  className?: string;
  isOpen: boolean;
  onClickOutside: (state?: boolean) => void;
}

function Drawer({ children, className, isOpen, onClickOutside }: DrawerProps) {
  const [drawerState, setDrawerState] = useState(isOpen);
  const drawerRef = useRef(null);

  useOnClickOutside(drawerRef, () => onClickOutside(false));

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (isOpen) {
      setDrawerState(true);
    } else {
      timeout = setTimeout(() => {
        setDrawerState(false);
      }, 250);
    }

    return () => clearTimeout(timeout);
  }, [isOpen]);

  if (!drawerState) return null;
  return (
    <Modal
      className={cx(styles.drawer_modal, {
        [styles.open]: isOpen,
        [styles.closed]: !isOpen,
      })}
    >
      <DrawerBody className={className} isOpen={isOpen} ref={drawerRef}>
        {children}
      </DrawerBody>
    </Modal>
  );
}

export default Drawer;
