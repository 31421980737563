import Icon from "assets/icons/Icon";
import useOnClickOutside from "components/generic/hooks/useOnClickOutside";
import ScoreRow from "components/ScoreRow";
import {
  filterScoresForSelection,
  getDimensionScores,
  Handlers,
  ReturnedResults,
  State,
} from "hooks/useReport";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdSpeed } from "react-icons/md";
import ActionButton from "../../ActionButton";
import Dropdown from "../../Dropdown";
import Score from "../../Score";
import TaskButtons from "../TaskButtons";
import styles from "./ClientTasks.module.scss";

/**
 * Tasks available to the client when they are viewing their dashboard. These are the buttons that appear in the top right of the dashboard.
 */

interface ClientTasksProps {
  activeTab: State["activeTab"];
  currentDrilldown: State["currentDrilldown"];
  benchmarkAvailable: State["benchmarkAvailable"];
  benchmarkIsActive: boolean;
  benchmarks: State["benchmarkData"];
  isLoading: boolean;
  clientScores: State["clientScores"];
  toggleRecommendations: Handlers["toggleRecommendations"];
  toggleBenchmark: Handlers["toggleBenchmark"];
}

const ClientTasks = ({
  activeTab,
  currentDrilldown,
  benchmarkAvailable,
  benchmarks,
  benchmarkIsActive,
  isLoading,
  clientScores,
  toggleRecommendations,
  toggleBenchmark,
}: ClientTasksProps) => {
  const tasksRef = useRef(null);
  const { t } = useTranslation();
  const [breakdownState, setBreakdownState] = useState(false);

  useOnClickOutside(tasksRef, () => setBreakdownState(false));

  let scoresToDisplay: ReturnedResults[];

  if (activeTab === "Overview") {
    scoresToDisplay = getDimensionScores(clientScores, benchmarks);
  } else {
    scoresToDisplay = filterScoresForSelection(
      clientScores,
      benchmarks,
      activeTab
    );
  }

  return (
    <TaskButtons ref={tasksRef}>
      {currentDrilldown === undefined && benchmarkAvailable && (
        <ActionButton
          isDisabled={isLoading}
          label={t("controls.benchmark")}
          popperPlacement="bottom"
          onClick={() => toggleBenchmark()}
          isActive={benchmarkIsActive}
        >
          {isLoading ? null : <MdSpeed />}
        </ActionButton>
      )}
      <ActionButton
        isDisabled={isLoading}
        popperPlacement="bottom"
        label={t("controls.scoreBreakdown")}
        onClick={() => setBreakdownState((prev) => !prev)}
      >
        {isLoading ? null : <Icon icon="GtMilitaryBadge" />}
      </ActionButton>
      <ActionButton
        isDisabled={isLoading}
        popperOffset={[-50, 10]}
        popperPlacement="bottom"
        label={t("controls.recommendations")}
        onClick={() => toggleRecommendations()}
      >
        {isLoading ? null : <Icon icon="GtChecklist" />}
      </ActionButton>
      {breakdownState && (
        <Dropdown>
          <div className={styles.scores}>
            <div className={styles.scores_rows}>
              {scoresToDisplay?.map((r, index) => {
                return (
                  <ScoreRow
                    {...r}
                    benchmarkAvailable={benchmarkAvailable}
                    benchmark={r.benchmark}
                    key={`${r.dimension}-${index}`}
                    className={styles.scores_row}
                  />
                );
              })}
            </div>
            <Score activeTab={activeTab} scores={scoresToDisplay} />
          </div>
        </Dropdown>
      )}
    </TaskButtons>
  );
};

export default ClientTasks;
