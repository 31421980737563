import React from "react";
import cx from "classnames";
import styles from "./IconButton.module.scss";
import Icon, { IconKeys } from "assets/icons/Icon";

interface IconButtonProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, "size" | "type"> {
  className?: string;
  children: React.ReactNode;
  component?: React.ElementType;
  color?: string;
  icon?: IconKeys;
  isDisabled?: boolean;
  label: string;
  to?: string;
  size?: string;
  variant?: string;
}

type Ref = HTMLButtonElement;

/**
 *
 * @returns A button, with an icon passed through the icon prop
 *
 * * As there is no text content, you should pass a label for what the button is doing. For example, for a save button you should pass the label "Save"
 */

const IconButton = React.forwardRef<Ref, IconButtonProps>((props, refProp) => {
  const {
    className,
    children,
    component: Component = "button",
    color,
    icon,
    isDisabled,
    label,
    size = "md",
    variant,
    ...restProps
  } = props;
  const classNames = cx(
    styles.button,
    {
      [styles.filled]: variant === "filled",
      [styles.outline]: variant === "outline",
      [styles.ghost]: variant === "ghost",
      [styles.white]: variant === "white",
      [styles.danger]: color === "red",
      [styles.lg]: size === "lg",
      [styles.md]: size === "md",
      [styles.sm]: size === "sm",
    },
    className
  );
  return (
    <Component
      ref={refProp}
      aria-label={label}
      disabled={isDisabled}
      className={classNames}
      {...restProps}
    >
      {!icon ? children : <Icon icon={icon} />}
    </Component>
  );
});

export default IconButton;
