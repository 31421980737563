import { useMsal } from "@azure/msal-react";
import DMA_LOGO from "assets/DMA-Icon.svg";
import Button from "components/generic/Button/Button";
import Link from "components/generic/Link";
import Text from "components/generic/Text/Text";
import { ClientParams } from "pages/AdminResults";
import React from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useBreakpoints, { Breakpoints } from "hooks/common/useBreakpoints";
import IconButton from "components/generic/IconButton/IconButton";
import { MdKeyboardReturn, MdLogout } from "react-icons/md";
import styles from "./Navigation.module.scss";

/** Returns a navbar, which changes depending on whether the user
 * is authenticated or not.
 */

interface NavigationProps {
  isAdminView?: boolean;
}

function Navigation({ isAdminView }: NavigationProps) {
  const { clientEmail } = useParams<ClientParams>();
  const breakpoints = useBreakpoints({ active: "md" });

  const { t } = useTranslation();
  return (
    <nav className={styles.nav}>
      <div className={styles.nav_wrapper}>
        <div className={styles.nav_container}>
          <LogoContainer />
          <Text className={styles.client_title}>
            {isAdminView && clientEmail
              ? `Viewing as ${clientEmail}`
              : isAdminView && !clientEmail
              ? "Clients"
              : t("navigator.your_results")}
          </Text>
        </div>
        {isAdminView && clientEmail && (
          <BackToDasboardLink breakpoints={breakpoints} />
        )}
        <LogoutButton breakpoints={breakpoints} />
      </div>
    </nav>
  );
}

export default Navigation;

function LogoContainer() {
  return (
    <div className={styles.logo_container}>
      <img
        src={DMA_LOGO}
        className={styles.logo}
        alt="Digital Maturity Assessment Logo"
      />
      <Text variant="bodyLarge" weight="bold" className={styles.logo_title}>
        Digital Maturity Assessment
      </Text>
    </div>
  );
}

const BackToDasboardLink = ({ breakpoints }: { breakpoints: Breakpoints }) => {
  if (breakpoints.isSm) {
    return (
      <IconButton
        label="Back to dashboard"
        className={styles.nav_link}
        component={RouterLink}
        to="/admin"
      >
        <MdKeyboardReturn />
      </IconButton>
    );
  }

  return (
    <Link className={styles.nav_link} component={RouterLink} to="/admin">
      Back to dashboard
    </Link>
  );
};

const LogoutButton = ({ breakpoints }: { breakpoints: Breakpoints }) => {
  const { instance, accounts } = useMsal();
  const handleLogout = async () => {
    const homeAccountId = accounts[0].homeAccountId;
    const logoutRequest = {
      account: instance.getAccountByHomeId(homeAccountId),
      redirectUri: "/",
      postLogoutRedirectUri: "/",
    };
    await instance.logoutRedirect(logoutRequest);
  };

  if (!accounts[0]) return null;

  if (breakpoints.isSm) {
    return (
      <IconButton
        className={styles.nav_button}
        variant="ghost"
        label="Logout"
        onClick={() => handleLogout()}
      >
        <MdLogout />
      </IconButton>
    );
  }

  return (
    <Button
      className={styles.nav_button}
      variant="ghost"
      size="xs"
      onClick={() => handleLogout()}
    >
      Log out
    </Button>
  );
};
