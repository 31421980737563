import { Dimensions, SubDimensions } from "types/dimensions";
import { Scores, AnswerLabels, Ranks, DrilldownData } from "hooks/useReport";
import { QuestionNumbers } from "types/questions";

const getParentDimension = (subDimension: SubDimensions): Dimensions => {
  if (
    [
      "Advanced Ecosystems and Partnerships Engagement",
      "Agile Process and Procedures",
      "Integrated Channels",
      "Intelligent Workflow and Operations",
    ].includes(subDimension)
  ) {
    return "Sustainable Operating Model";
  }

  if (
    [
      "Digital Skills",
      "Digital Workplace of the Future",
      "Learning, Development and Research",
      "Talent Retention, Growth and Engagement",
    ].includes(subDimension)
  ) {
    return "Capabilities";
  }

  if (
    [
      "Enabled DevOps",
      "Focused Security",
      "Intelligent Automation",
      "Optimised Infrastructure, Architecture and Applications",
    ].includes(subDimension)
  ) {
    return "Technology";
  }

  if (
    [
      "Communication and Collaboration",
      "Diversity & Inclusion",
      "Experimentation",
      "Organisational Adaptability",
    ].includes(subDimension)
  ) {
    return "Culture";
  }

  if (
    [
      "Data Analytics and Visualisation",
      "Data Platforms",
      "Data Science and Big Data",
      "Data Strategy and Governance",
    ].includes(subDimension)
  ) {
    return "Data";
  }

  return "Customer";
};

export const getQuestionSubDimension = (
  questionNumber: QuestionNumbers
): SubDimensions => {
  if (["1.1.1", "1.1.2", "1.1.3", "1.1.4", "1.1.5"].includes(questionNumber)) {
    return "Integrated Channels";
  }

  if (["1.2.1", "1.2.2", "1.2.3", "1.2.4", "1.2.5"].includes(questionNumber)) {
    return "Agile Process and Procedures";
  }

  if (["1.3.1", "1.3.2", "1.3.3", "1.3.4", "1.3.5"].includes(questionNumber)) {
    return "Intelligent Workflow and Operations";
  }

  if (["1.4.1", "1.4.2", "1.4.3", "1.4.4", "1.4.5"].includes(questionNumber)) {
    return "Advanced Ecosystems and Partnerships Engagement";
  }

  if (
    ["2.1.1", "2.1.2", "2.1.3", "2.1.4", "2.1.5", "2.1.8"].includes(
      questionNumber
    )
  ) {
    return "Digital Skills";
  }

  if (["2.2.1", "2.2.2", "2.2.3", "2.2.4", "2.2.5"].includes(questionNumber)) {
    return "Learning, Development and Research";
  }

  if (["2.3.1", "2.3.2", "2.3.3", "2.3.4", "2.3.5"].includes(questionNumber)) {
    return "Talent Retention, Growth and Engagement";
  }

  if (["2.4.1", "2.4.2", "2.4.3", "2.4.4", "2.4.5"].includes(questionNumber)) {
    return "Digital Workplace of the Future";
  }

  if (["3.1.1", "3.1.2", "3.1.3", "3.1.4", "3.1.5"].includes(questionNumber)) {
    return "Focused Security";
  }

  if (["3.2.1", "3.2.2", "3.2.3", "3.2.4", "3.2.5"].includes(questionNumber)) {
    return "Optimised Infrastructure, Architecture and Applications";
  }

  if (["3.3.1", "3.3.2", "3.3.3", "3.3.4", "3.3.5"].includes(questionNumber)) {
    return "Enabled DevOps";
  }

  if (["3.4.1", "3.4.2", "3.4.3", "3.4.4", "3.4.5"].includes(questionNumber)) {
    return "Intelligent Automation";
  }

  if (["4.1.1", "4.1.2", "4.1.3", "4.1.4", "4.1.5"].includes(questionNumber)) {
    return "Experimentation";
  }

  if (["4.2.1", "4.2.2", "4.2.3", "4.2.4", "4.2.5"].includes(questionNumber)) {
    return "Diversity & Inclusion";
  }

  if (["4.3.1", "4.3.2", "4.3.3", "4.3.4", "4.3.5"].includes(questionNumber)) {
    return "Communication and Collaboration";
  }

  if (["4.4.1", "4.4.2", "4.4.3", "4.4.4", "4.4.5"].includes(questionNumber)) {
    return "Organisational Adaptability";
  }

  if (["5.1.1", "5.1.2", "5.1.3", "5.1.4", "5.1.5"].includes(questionNumber)) {
    return "Data Strategy and Governance";
  }

  if (["5.2.1", "5.2.2", "5.2.3", "5.2.4", "5.2.5"].includes(questionNumber)) {
    return "Data Science and Big Data";
  }

  if (["5.3.1", "5.3.2", "5.3.3", "5.3.4", "5.3.5"].includes(questionNumber)) {
    return "Data Analytics and Visualisation";
  }

  if (["5.4.1", "5.4.2", "5.4.3", "5.4.4", "5.4.5"].includes(questionNumber)) {
    return "Data Platforms";
  }

  if (["6.1.1", "6.1.2", "6.1.3", "6.1.4", "6.1.5"].includes(questionNumber)) {
    return "Customer Engagement";
  }

  if (["6.2.1", "6.2.2", "6.2.3", "6.2.4", "6.2.5"].includes(questionNumber)) {
    return "Customer Experience";
  }

  if (["6.3.1", "6.3.2", "6.3.3", "6.3.4", "6.3.5"].includes(questionNumber)) {
    return "Customer Insights";
  }

  return "Customer Trust";
};

const spliceInitialData = (data: string) =>
  data
    .split("\r\n")
    .filter((r) => r !== "")
    .slice(1);

export const parseTotalSubmissions = (data: string): number => {
  const split = spliceInitialData(data);

  return parseInt(split[0]);
};

export const parseSubDimension = (data: string): Scores[] => {
  const split = spliceInitialData(data);
  // From column position in Power BI report
  const scoreIndex = 1;
  const subDimensionIndex = 2;

  const parsed = split.map((curr) => {
    const splitData = curr.split(",");

    const score = Math.round(parseFloat(splitData[scoreIndex]));
    const subDimension = splitData
      .slice(subDimensionIndex, splitData.length)
      .join()
      .replace(/^"|"$/g, "") as SubDimensions;

    return {
      dimension: getParentDimension(subDimension),
      qcode: subDimension,
      score,
    };
  });

  return parsed;
};

export type ParsedSubDimensionBenchmarks = {
  score: number;
  qcode: SubDimensions;
  dimension: Dimensions;
};

export const parseSubDimensionBenchmarks = (
  data: string,
  totalSubmissions: number
): ParsedSubDimensionBenchmarks[] => {
  const split = spliceInitialData(data);

  // From column position in Power BI report
  const benchmarkIndex = 0;
  const subDimensionIndex = 1;

  const parsed = split.map((curr) => {
    const splitData = curr.split(",");

    const benchmark = Math.round(parseFloat(splitData[benchmarkIndex]));
    const subDimension = splitData
      .slice(subDimensionIndex, splitData.length)
      .join()
      .replace(/^"|"$/g, "") as SubDimensions;

    return {
      score: totalSubmissions === 1 ? 0 : benchmark,
      dimension: getParentDimension(subDimension),
      qcode: subDimension,
    };
  });
  return parsed;
};

const questionMatrix = (
  oldAnswer: AnswerLabels,
  newAnswer: AnswerLabels
): AnswerLabels => {
  // Strongly Agree
  if (oldAnswer === "Strongly Agree") {
    return "Strongly Agree";
  }

  // Agree
  if (oldAnswer === "Agree") {
    if (newAnswer === "Strongly Agree") {
      return newAnswer;
    }

    return oldAnswer;
  }

  // Not Sure
  if (oldAnswer === "Not Sure") {
    if (
      newAnswer === "Strongly Agree" ||
      newAnswer === "Agree" ||
      newAnswer === "Disagree" ||
      newAnswer === "Strongly Disagree"
    ) {
      return newAnswer;
    }

    return oldAnswer;
  }

  // Disagree
  if (oldAnswer === "Disagree") {
    if (newAnswer === "Strongly Agree" || newAnswer === "Agree") {
      return newAnswer;
    }

    return oldAnswer;
  }

  // Strongly Disagree
  if (newAnswer === "Strongly Agree" || newAnswer === "Agree") {
    return newAnswer;
  }

  if (newAnswer === "Not Sure") {
    return "Strongly Disagree";
  }

  return "Disagree";
};

type ParsedMostCommonAnswers = {
  averageAnswer: AnswerLabels;
  question: string;
  qcode: SubDimensions;
};

export const parseMostCommmonAnswers = (
  data: string
): ParsedMostCommonAnswers[] => {
  const split = data
    .split("\r\n")
    .splice(1)
    .filter((d) => d !== "");

  const possibleAnswers = [
    "Strongly Agree",
    "Agree",
    "Not Sure",
    "Disagree",
    "Strongly Disagree",
  ];

  const parsed = split.reduce((acc: ParsedMostCommonAnswers[], curr) => {
    const splitData = curr.split(",");
    const subDimensionIndex = 0;
    // From column position in Power BI report
    let averageAnswerIndex = 1;
    let parsedQuestionIndex = 2;
    let subDimensionHasComma = false;

    /**
     *
     * Some sub-dimensions contain a comma, which impacts the split. Also, some questions are repeated and must be edited using the questionMatrix function.
     *
     *  On every loop of the reduce method, the question is pushed to an array, possibleAnswers. Using this array, we can check if this question already exists.
     * If so, the chosen answer needs to be determined and replace the existing one.
     *
     *
     **/

    if (!possibleAnswers.includes(splitData[averageAnswerIndex])) {
      const newAverageAnswerIndex = splitData.findIndex((a) =>
        possibleAnswers.includes(a)
      );

      averageAnswerIndex = newAverageAnswerIndex;
      parsedQuestionIndex = newAverageAnswerIndex + 1;
      subDimensionHasComma = true;
    }

    const subDimension = subDimensionHasComma
      ? (splitData
          .slice(subDimensionIndex, averageAnswerIndex)
          .join()
          .replace(/^"|"$/g, "") as SubDimensions)
      : (splitData[subDimensionIndex].replace(/^"|"$/g, "") as SubDimensions);

    const averageAnswer = splitData[averageAnswerIndex].replace(
      /^"|"$/g,
      ""
    ) as AnswerLabels;

    const parsedQuestion = splitData
      .slice(parsedQuestionIndex)
      .join()
      .replace(/^"|"$/g, "");

    const questionAlreadySubmitted = acc.find(
      (q) => q.question === parsedQuestion
    );
    let newAverageAnswer = averageAnswer;

    if (questionAlreadySubmitted !== undefined) {
      newAverageAnswer = questionMatrix(
        questionAlreadySubmitted.averageAnswer,
        averageAnswer
      );

      const filteredAcc = acc.filter((q) => q.question !== parsedQuestion);
      return [
        ...filteredAcc,
        {
          averageAnswer: newAverageAnswer,
          question: parsedQuestion,
          qcode: subDimension,
        },
      ];
    }

    return [
      ...acc,
      {
        averageAnswer: newAverageAnswer,
        question: parsedQuestion,
        qcode: subDimension,
      },
    ];
  }, []);

  return parsed;
};

type ParsedClientsAnswers = {
  question: string;
  answer: AnswerLabels;
  qcode: SubDimensions;
};

export const parseClientsAnswers = (data: string): ParsedClientsAnswers[] => {
  const split = data
    .split("\r\n")
    .splice(1)
    .filter((d) => d !== "");

  const parsed = split.reduce((acc: ParsedClientsAnswers[], curr) => {
    const splitData = curr.split(",");
    // From column position in Power BI report
    const answerIndex = 1;
    const questionIndex = 2;

    const answer = splitData[answerIndex] as AnswerLabels;
    const parsedQuestion = splitData
      .slice(questionIndex)
      .join()
      .replace(/^"|"$/g, "");

    const questionNumber = parsedQuestion.slice(0, 5) as QuestionNumbers;
    const questionQcode = getQuestionSubDimension(questionNumber);

    return [
      ...acc,
      {
        question: parsedQuestion,
        answer: answer,
        qcode: questionQcode,
      },
    ];
  }, []);

  return parsed;
};

type ParsedClientSubmissionsData = {
  email: string;
  score: number;
};

export const parseClientsSubmissionsData = (
  data: string
): ParsedClientSubmissionsData[] => {
  const split = spliceInitialData(data);

  return split.map((client) => {
    const [email, score] = client.split(",");

    return {
      email,
      score: parseInt(score),
    };
  });
};

export const mergeAnswersData = (
  mostCommonAnswers: ParsedMostCommonAnswers[],
  parsedAnswers: ParsedClientsAnswers[]
): DrilldownData[] =>
  parsedAnswers.map((a) => {
    if (mostCommonAnswers.length === 0) {
      return {
        ...a,
        averageAnswer: undefined,
      };
    }
    const matchingQuestion = mostCommonAnswers.find(
      (ca) => ca.question === a.question
    );

    return {
      ...a,
      averageAnswer: matchingQuestion!.averageAnswer,
      qcode: matchingQuestion!.qcode,
    };
  });

export const getRankLabelByScore = (
  score: number,
  subDimensions = false
): Ranks => {
  const lowestLimit = subDimensions ? 20 : 120;
  const lowerLimit = subDimensions ? 40 : 240;
  const higherLimit = subDimensions ? 60 : 360;
  if (score < lowestLimit) return "Nascent";

  if (score >= lowestLimit && score < lowerLimit) return "Developing";

  if (score >= lowerLimit && score < higherLimit) return "Maturing";

  return "Leading";
};
