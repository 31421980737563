import React from "react";
import styles from "./ReportControls.module.scss";
import { Breakpoints } from "hooks/common/useBreakpoints";

interface ReportControls {
  children: React.ReactNode;
  breakpoints: Breakpoints;
}
/**
 * Container for report controls overlay
 */
function ReportControls({ children, breakpoints }: ReportControls) {
  if (
    breakpoints?.active === "sm" ||
    (breakpoints?.active === "md" && breakpoints?.isLandscape)
  ) {
    return null;
  }
  return (
    <div className={styles.controls}>
      <div className={styles.wrapper}>{children}</div>
    </div>
  );
}

export default ReportControls;
