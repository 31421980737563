import { UseTooltipProps } from "components/generic/hooks/useTooltip";
import Text from "components/generic/Text/Text";
import Tooltip from "components/generic/Tooltip";
import Skeleton from "components/Skeleton";
import cx from "classnames";
import isArray from "lodash/isArray";
import React from "react";
import styles from "./ActionButton.module.scss";

interface ActionButtonProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, "label">,
    Pick<
      UseTooltipProps,
      "popperPlacement" | "popperOffset" | "popperEnabled"
    > {
  children?: React.ReactNode;
  className?: string;
  component?: React.ElementType;
  label: string | string[];
  isActive?: boolean;
  isDisabled?: boolean;
}

function ActionButton({
  children,
  className,
  component: Component = "button",
  label,
  popperPlacement = "left",
  popperOffset = [0, 10],
  popperEnabled = true,
  isActive,
  isDisabled,
  ...rest
}: ActionButtonProps) {
  return (
    <>
      <Tooltip
        popperPlacement={popperPlacement}
        popperOffset={popperOffset}
        popperEnabled={popperEnabled}
        label={isArray(label) ? label.join(" ") : label}
      >
        {({ setReferenceElement, setVisible }) => (
          <Component
            disabled={isDisabled}
            type="button"
            onFocus={popperEnabled ? () => setVisible(true) : null}
            onBlur={popperEnabled ? () => setVisible(false) : null}
            onMouseEnter={popperEnabled ? () => setVisible(true) : null}
            onMouseLeave={popperEnabled ? () => setVisible(false) : null}
            ref={setReferenceElement}
            className={cx(
              styles.button,
              {
                [styles.button_active]: isActive,
              },
              className
            )}
            {...rest}
          >
            <div className={styles.button_wrapper}>
              {children || <Skeleton width={32} height={32} />}
            </div>
          </Component>
        )}
      </Tooltip>
    </>
  );
}

export default ActionButton;

export const ActionButtonBadge = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <div className={styles.badge}>
    <Text variant="caption">{children}</Text>
  </div>
);
