import React from "react";
import cx from "classnames";
import { string, oneOf } from "prop-types";
import styles from "./Spinner.module.scss";

const Spinner = ({ className, color, size }) => {
  const classNames = cx(
    styles.spinner,
    {
      [styles.xxs]: size === "xxs",
      [styles.xs]: size === "xs",
      [styles.sm]: size === "sm",
      [styles.md]: size === "md",
      [styles.lg]: size === "lg",
      [styles.xl]: size === "xl",
      [styles.purple]: color === "purple",
      [styles.currentColor]: color === "currentColor",
    },
    className
  );

  return <span className={classNames} />;
};

export default Spinner;

Spinner.propTypes = {
  /** For CSS customisation */
  className: string,
  /** The color of the spinner. Defaults to text colour of container */
  color: oneOf(["purple", "currentColor"]),
  /** Size of the spinner */
  size: oneOf(["xxs", "xs", "sm", "md", "lg", "xl"]),
};

Spinner.defaultProps = {
  color: "currentColor",
  size: "md",
};
