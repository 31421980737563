import React, { useMemo } from "react";
import cx from "classnames";
import Ranking from "./Ranking";
import Icon from "assets/icons/Icon";
import Text from "components/generic/Text/Text";
import Intro from "./Intro";
import ColouredIcon from "./ColouredIcon";
import styles from "./Body.module.scss";
import {
  getLabelRank,
  ScoresWithRecommendations,
} from "hooks/useRecommendations";
import { Dimensions } from "types/dimensions";
import { useTranslation } from "react-i18next";
import { getDimensionTranslationKey } from "hooks/useReport";

interface DimensionProps {
  benchmarkAvailable: boolean;
  tab: Dimensions;
  scoresWithRecommendations: ScoresWithRecommendations[];
}

const Dimension = ({
  benchmarkAvailable,
  tab,
  scoresWithRecommendations,
}: DimensionProps) => {
  const { t } = useTranslation();
  const data = useMemo<ScoresWithRecommendations | undefined>(
    () => scoresWithRecommendations.find((d) => d.dimension === tab),
    [tab, scoresWithRecommendations]
  );

  if (!data) return null;

  const rank = getLabelRank(data.score);

  return (
    <>
      <DimensionHeader>
        <Ranking
          label={`${t(`dimensions.${getDimensionTranslationKey(tab)}`).join(
            ""
          )}`}
          rank={t(`ranks.${rank}`)}
        />
        <Badge
          dimension={tab}
          score={data.score}
          benchmarkAvailable={benchmarkAvailable}
          benchmark={data.benchmark}
        />
      </DimensionHeader>
      <Intro
        intro={t(
          `recommendationsDimensionsIntro.${getDimensionTranslationKey(tab)}`
        )}
      />
      <Suggestions
        dimension={tab}
        suggestions={data.clientRecommendations.suggestions}
      />
      {data.clientRecommendations.roadmap.length > 0 && (
        <Roadmap dimension={tab} items={data.clientRecommendations.roadmap} />
      )}
    </>
  );
};

export default Dimension;

const DimensionHeader = ({ children }: { children: React.ReactNode }) => (
  <div className={styles.dimension_header}>{children}</div>
);

interface BadgeProps {
  benchmarkAvailable: boolean;
  dimension: Dimensions;
  score: number;
  benchmark: number;
}

const Badge = ({
  benchmarkAvailable,
  dimension,
  score,
  benchmark,
}: BadgeProps) => {
  const { t } = useTranslation();
  return (<>
    <div
      className={cx(styles.dimension_badge, {
        [styles.teal]: dimension === "Sustainable Operating Model",
        [styles.orange]: dimension === "Capabilities",
        [styles.purple]: dimension === "Technology",
        [styles.green]: dimension === "Customer",
        [styles.red]: dimension === "Culture",
        [styles.softGray]: dimension === "Data",
      })}
    >
      <ColouredIcon dimension={dimension} />
      <div className={styles.dimension_badge_group}>
        <div className={styles.dimension_badge_group_contents}>
          <Text>{t("dimensions.your_score")}</Text>
          <Text weight="bold">{score}</Text>
        </div>
        {benchmarkAvailable && (
          <div className={styles.dimension_badge_group_contents}>
            <Text>{t("dimensions.benchmark")}</Text>
            <Text weight="bold">{benchmark}</Text>
          </div>
        )}
      </div>
    </div></>
  )
};

const SectionTitle = ({ children }: { children: React.ReactNode }) => (
  <Text className={styles.section_title} weight="bold">
    {children}
  </Text>
);

interface SuggestionsProps {
  suggestions: string[];
  dimension: Dimensions;
}

const Suggestions = ({ suggestions, dimension }: SuggestionsProps) => {
  const { t } = useTranslation();
  const suggest_header = t("dimensions.suggest_header");
  const no_suggest_text_pre = t("dimensions.no_suggest_text_prefix");
  const no_suggest_text_suf = t("dimensions.no_suggest_text_suffix");
  return (<>
    <div className={styles.suggestions}>
      <SectionTitle>{suggest_header}</SectionTitle>
      {suggestions?.length === 0 ? (
        <Text className={styles.suggestions_alternate}>
          {no_suggest_text_pre} {dimension} {no_suggest_text_suf}
        </Text>
      ) : (
        <ul className={styles.suggestions_list}>
          {suggestions.map((s, index) => (
            <Text
              as="li"
              key={`${s}-${index}`}
              className={styles.suggestions_item}
            >
              <Icon
                icon="GtCheckmark"
                className={cx(styles.suggestions_icon, {
                  [styles.teal]: dimension === "Sustainable Operating Model",
                  [styles.orange]: dimension === "Capabilities",
                  [styles.purple]: dimension === "Technology",
                  [styles.green]: dimension === "Customer",
                  [styles.red]: dimension === "Culture",
                  [styles.softGray]: dimension === "Data",
                })}
              />
              {s}
            </Text>
          ))}
        </ul>
      )}
    </div>
    </>
  )
};
interface RoadmapProps {
  dimension: Dimensions;
  items: string[];
}

const Roadmap = ({ dimension, items }: RoadmapProps) => {
  const { t } = useTranslation();

  const getTitleAndDuration = (index: number) => {
    if (index === 0) {
      return [t("recommendations.roadmap.short_term"), t("recommendations.roadmap.less_3_month")];
    }

    if (index === 1) {
      return [t("recommendations.roadmap.medium_term"), t("recommendations.roadmap.less_6_month")];
    }

    return [t("recommendations.roadmap.long_term"), t("recommendations.roadmap.greater_6_month")];
  };
  return (
    <div className={styles.roadmap}>
      <SectionTitle>{t("dimensions.roadmap_header")}</SectionTitle>
      {items.map((item, index) => {
        const [title, duration] = getTitleAndDuration(index);
        return (
          <div
            className={cx(styles.roadmap_details, {
              [styles.teal]: dimension === "Sustainable Operating Model",
              [styles.orange]: dimension === "Capabilities",
              [styles.purple]: dimension === "Technology",
              [styles.green]: dimension === "Customer",
              [styles.red]: dimension === "Culture",
              [styles.softGray]: dimension === "Data",
            })}
            key={index}
          >
            <ColouredIcon dimension={dimension} />
            <div className={styles.roadmap_details_group}>
              <Text className={styles.roadmap_details_title}>{title}</Text>
              <Text
                variant="caption"
                className={styles.roadmap_details_duration}
              >
                {duration}
              </Text>
              <Text className={styles.roadmap_details_description}>{item}</Text>
            </div>
          </div>
        );
      })}
    </div>
  );
};

Roadmap.defaultProps = {
  items: [
    {
      description: [
        "Utilise LEAN and Agile methodologies to develop and manage collaborative brainstorming sessions with the aim of identifying business improvement initiatives",
      ],
      duration: "< 3 months",
      title: "Short term",
    },
    {
      description: [
        "Define, document and report short to medium term Digital Transformation objectives and align them to the organisations objectives and Leadership goals",
      ],
      duration: "< 6 months",
      title: "Medium term",
    },
    {
      description: [
        "Leverage the Ecosystem to gain access to resources such as technology and people to improve the organisation’s ability to innovate and grow",
      ],
      duration: "> 6 months",
      title: "Long term",
    },
  ],
};
