import React, { useState } from "react";
import cx from "classnames";
import { PowerBIEmbed } from "powerbi-client-react";
import { models, Report } from "powerbi-client";
import styles from "./PowerBI.module.scss";
import { Handlers } from "hooks/useReport";
import { useErrorHandler } from "react-error-boundary";
import { InitialFilter } from "pages/ResultsDashboard";
import isEmpty from "lodash/isEmpty";
interface PowerBIProps {
  accessToken?: string;
  initializeReportCallback: Handlers["initializeReportCallback"];
  isLoaded: boolean;
  initialFilter?: InitialFilter;
}

function PowerBI({
  accessToken,
  initializeReportCallback,
  isLoaded,
  initialFilter,
}: PowerBIProps) {
  const [error, setError] = useState<Error | null>(null);
  useErrorHandler(error);

  if (isLoaded) return null;

  return (
    <PowerBIWrapper>
      <PowerBIEmbed
        embedConfig={{
          filters: isEmpty(initialFilter)
            ? undefined
            : [{ ...(initialFilter as models.IBasicFilter) }],
          accessToken,
          id: process.env.REACT_APP_POWERBI_REPORT_ID,
          embedUrl: process.env.REACT_APP_POWERBI_EMBED_URL,
          settings: {
            background: models.BackgroundType.Transparent,
            panes: {
              filters: {
                visible: false,
              },
              pageNavigation: {
                visible: false,
              },
            },
            visualSettings: {
              visualHeaders: [
                {
                  settings: {
                    visible: false,
                  },
                },
              ],
            },
          },
          tokenType: models.TokenType.Aad,
          type: "report",
        }}
        eventHandlers={
          new Map([
            [
              "loaded",
              (_, embeddedReport) => {
                initializeReportCallback(embeddedReport as Report);
              },
            ],
            [
              "error",
              () => {
                setError(new Error("No report available"));
              },
            ],
          ])
        }
        cssClassName={cx(styles.report)}
      />
    </PowerBIWrapper>
  );
}

export default PowerBI;

function PowerBIWrapper({ children }: { children: React.ReactNode }) {
  return (
    <section className={styles.wrapper}>
      <div className={styles.wrapper_inner}>{children}</div>
    </section>
  );
}
