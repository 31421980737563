import React from "react";
import Text from "components/generic/Text/Text";
import styles from "./Body.module.scss";
import isArray from "lodash/isArray";

interface IntroProps {
  intro: string | string[];
}

const Intro = ({ intro }: IntroProps) => (
  <div className={styles.intro}>
    {isArray(intro) ? (
      intro.map((p) => <Text key={p}>{p}</Text>)
    ) : (
      <Text>{intro}</Text>
    )}
  </div>
);

export default Intro;
