import useMediaQuery from "./useMediaQuery";

export interface UseBreakpointProps {
  active?: "sm" | "md" | "lg";
}

export type Breakpoints = {
  active: string;
  isLandscape: boolean;
  isLg: boolean;
  isMd: boolean;
  isSm: boolean;
  orientation: string;
};

/**
 * Get a set of boolean representing which breakpoint is active
 * and which breakpoints are inactive.
 *
 */
export default function useBreakpoints(props: UseBreakpointProps) {
  const { active = "sm" } = props;
  const breakpoints: Breakpoints = {
    active,
    isLandscape: useMediaQuery("(orientation:landscape)"),
    isLg: useMediaQuery("(min-width: 1025px)"),
    isMd: useMediaQuery("(min-width: 769px) and (max-width: 1024px)"),
    isSm: useMediaQuery("(max-width: 768px)"),
    orientation: "portrait",
  };
  breakpoints.isLandscape
    ? (breakpoints.orientation = "landscape")
    : (breakpoints.orientation = "portrait");
  if (breakpoints.isSm) breakpoints.active = "sm";
  if (breakpoints.isMd) breakpoints.active = "md";
  if (breakpoints.isLg) breakpoints.active = "lg";
  return breakpoints;
}
