import React from "react";
import { string } from "prop-types";
import gtIcons from "./iconsLib";

/**
 *
 * @returns Generate icons by passing through a string, which will pull the path for the icon from /iconsLib
 */

export type IconKeys = keyof typeof gtIcons;

interface IconProps {
  icon: IconKeys;
  color?: string;
  className?: string;
  viewBox?: string;
}

function Icon({
  icon,
  color = "currentColor",
  className,
  viewBox = "0 0 16 16",
  ...rest
}: IconProps) {
  return (
    <svg
      className={className}
      viewBox={viewBox}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d={gtIcons[icon]} />
    </svg>
  );
}

export default Icon;

Icon.propTypes = {
  color: string,
  icon: string.isRequired,
  viewBox: string,
};

Icon.defaultProps = {
  color: "currentColor",
  viewBox: "0 0 16 16",
};
