import React from "react";
import cx from "classnames";
import styles from "./Link.module.scss";
import Icon, { IconKeys } from "assets/icons/Icon";

/**
 *
 * @returns A link component, with an optional icon.
 *
 */

interface LinkProps extends Omit<React.HTMLProps<HTMLAnchorElement>, "size"> {
  children: React.ReactNode;
  className?: string;
  component?: React.ElementType;
  href?: string;
  icon?: IconKeys;
  isDisabled?: boolean;
  to?: string;
  size?: "lg" | "md" | "sm";
}

const Link = ({
  children,
  className,
  component: Component = "a",
  href,
  icon,
  isDisabled,
  size = "lg",
  ...restProps
}: LinkProps) => {
  const classNames = cx(
    styles.link,
    {
      [styles.disabled]: isDisabled,
      [styles.lg]: size === "lg",
      [styles.md]: size === "md",
      [styles.sm]: size === "sm",
    },
    className
  );

  return (
    <Component
      className={classNames}
      href={href}
      disabled={isDisabled}
      {...restProps}
    >
      {children}
      {icon && <Icon icon={icon} />}
    </Component>
  );
};

export default Link;
