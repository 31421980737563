import Text from "components/generic/Text/Text";
import { useTranslation } from "react-i18next";
import styles from "./Recommendations.module.scss";

function Header() {
  const { t } = useTranslation();
  return (
    <div className={styles.header}>
      <Text className={styles.header_title} variant="subheading" weight="bold">
        {t("controls.recommendations")}
      </Text>
    </div>
  );
}

export default Header;
