import { ReturnedResults, TabsType } from "hooks/useReport";
import { getRankLabelByScore } from "utils/function";
import { useTranslation } from "react-i18next";
import Text from "components/generic/Text/Text";
import styles from "./Score.module.scss";

interface ScoreProps {
  activeTab: TabsType;
  scores: ReturnedResults[];
}

function Score({ activeTab, scores }: ScoreProps) {
  let scoreToShow = 0;
  const { t } = useTranslation();
  scoreToShow = scores.reduce((acc, curr) => acc + curr.score, 0);
  const label = getRankLabelByScore(scoreToShow, activeTab !== "Overview");

  const totalScore = activeTab === "Overview" ? "480" : "80";
  return (
    <div className={styles.score}>
      <Text className={styles.score_rank} variant="bodyLarge" weight="bold">
        {t(`ranks.${label}`)}
      </Text>
      <Text className={styles.score_amount} variant="bodyLarge">
        {scoreToShow} / {totalScore}
      </Text>
    </div>
  );
}

export default Score;
