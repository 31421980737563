import React, { useMemo } from "react";
import Ranking, { RankingGroup } from "./Ranking";
import Intro from "./Intro";
import ScoreRow from "components/ScoreRow";
import ColouredIcon from "./ColouredIcon";
import Text from "components/generic/Text/Text";
import { getRankLabelByScore } from "utils/function";
import styles from "./Body.module.scss";
import { BodyProps } from ".";
import { Dimensions } from "types/dimensions";
import { useTranslation } from "react-i18next";

interface OverviewProps
  extends Pick<
    BodyProps,
    | "totalScore"
    | "scoresWithRecommendations"
    | "handleSetTab"
    | "benchmarkAvailable"
  > {
  intro: string | string[];
}

const Overview = ({
  benchmarkAvailable,
  totalScore,
  intro,
  scoresWithRecommendations,
  handleSetTab,
}: OverviewProps) => {
  const { t } = useTranslation();
  const rankLabel = useMemo(
    () => getRankLabelByScore(totalScore),
    [totalScore]
  );
  const score = t("recommendations.overall.score");
  return (
    <>
      <RankingGroup>
        <Ranking rank={t(`ranks.${rankLabel}`)} label="Overall rank" />
        <Ranking aligned="end" rank={`${totalScore} / 480`} label={score} />
      </RankingGroup>
      <Intro intro={intro} />
      <ScoreCards>
        {scoresWithRecommendations?.map((s) => (
          <ScoreCard
            benchmarkAvailable={benchmarkAvailable}
            handleSetTab={handleSetTab}
            key={s.dimension}
            {...s}
          />
        ))}
      </ScoreCards>
    </>
  );
};

export default Overview;

function ScoreCards({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation();
  return (
    <div className={styles.cards}>
      <Text className={styles.cards_title}>
        {t("recommendations.overall.header")}
      </Text>
      {children}
    </div>
  );
}

interface ScoreCardProps
  extends Pick<BodyProps, "handleSetTab" | "benchmarkAvailable"> {
  dimension: Dimensions;
  score: number;
  benchmark: number;
}

export function ScoreCard({
  benchmarkAvailable,
  dimension,
  score,
  benchmark,
  handleSetTab,
}: ScoreCardProps) {
  return (
    <button
      type="button"
      onClick={() => handleSetTab(dimension)}
      className={styles.cards_card}
    >
      <ColouredIcon dimension={dimension} />
      <ScoreRow
        benchmarkAvailable={benchmarkAvailable}
        className={styles.cards_card_score}
        score={score}
        benchmark={benchmark}
        dimension={dimension}
      />
    </button>
  );
}
