import Icon from "assets/icons/Icon";
import Text from "components/generic/Text/Text";
import Skeleton from "components/Skeleton";
import {
  CrumbType,
  getDimensionTranslationKey,
  getSubDimensionTranslationKey,
} from "hooks/useReport";
import { useTranslation } from "react-i18next";
import { Dimensions, SubDimensions } from "types/dimensions";
import { valueIsDimension } from "utils/reportHelpers";
import ControlWrapper from "../ControlWrapper/ControlWrapper";
import styles from "./BreadCrumbs.module.scss";

interface BreadCrumbsProps {
  crumbs: CrumbType[];
  isLoading: boolean;
}

function BreadCrumbs({ crumbs, isLoading }: BreadCrumbsProps) {
  return (
    <ControlWrapper aria-label="breadcrumbs" className={styles.crumbs}>
      {isLoading ? (
        <Skeleton width={72} />
      ) : (
        crumbs?.map((crumb, index, crumbsArray) => (
          <Crumb
            chevronActive={index + 1 < crumbsArray.length}
            crumb={crumb}
            key={crumb}
          />
        ))
      )}
    </ControlWrapper>
  );
}

export default BreadCrumbs;

interface CrumbProps {
  crumb: CrumbType;
  chevronActive: boolean;
}

const Crumb = ({ crumb, chevronActive }: CrumbProps) => {
  const { t } = useTranslation();
  let label = undefined;

  if (crumb === "Overview") label = t("controls.overview");

  if (label === undefined) {
    if (valueIsDimension(crumb) === true) {
      label = t(
        `dimensions.${getDimensionTranslationKey(crumb as Dimensions)}`
      );
    } else {
      label = t(
        `subDimensions.${getSubDimensionTranslationKey(crumb as SubDimensions)}`
      ).join(" ");
    }
  }

  return (
    <Text variant="caption" className={styles.crumb}>
      {label} {chevronActive && <Separator />}
    </Text>
  );
};
const Separator = () => (
  <span className={styles.separator}>
    <Icon icon="GtChevronSmallRight" />
  </span>
);
