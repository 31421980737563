import { ChartOptions } from "chart.js";

const options: ChartOptions<"radar"> = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  responsive: true,
  scales: {
    r: {
      max: 80,
      min: 0,
      pointLabels: {
        font: {
          family: "GT Walsheim Pro",
          size: 16,
        },
      },
      beginAtZero: false,
      ticks: {
        backdropColor: "transparent",
        font: {
          family: "GT Walsheim Pro",
          size: 16,
        },
        stepSize: 20,
      },
    },
  },
};

export default options;
