import cx from "classnames";
import Text from "components/generic/Text/Text";
import Icon from "assets/icons/Icon";
import styles from "./ErrorComponent.module.scss";
import { FallbackProps } from "react-error-boundary";

function ErrorComponent({ error }: FallbackProps) {
  if (error.message === "No report available") {
    return (
      <ErrorWrapper>
        <NoReportError />
      </ErrorWrapper>
    );
  }
  return (
    <ErrorWrapper>
      <SomethingWentWrongError />
    </ErrorWrapper>
  );
}

export default ErrorComponent;

const ErrorWrapper = ({ children }: { children: React.ReactNode }) => (
  <section className={styles.error}>
    <div className={styles.error_wrapper}>
      <div className={styles.error_container}>{children}</div>
    </div>
  </section>
);

const NoReportError = () => {
  return (
    <>
      <div className={cx(styles.error_details, styles.error_details_centered)}>
        <Text className={styles.error_title} weight="bold" variant="h3">
          Your report is not available yet.
        </Text>
        <div className={styles.error_group}>
          <Text className={styles.error_description}>
            It will be shared with you when it is ready.
          </Text>
          <Text className={styles.error_description}>
            Please also ensure you have a Power BI pro license attached to this
            account.
          </Text>
        </div>
      </div>
    </>
  );
};

const SomethingWentWrongError = () => (
  <>
    <div className={styles.error_icon}>
      <Icon icon="GtWarning" />
    </div>
    <div className={styles.error_details}>
      <Text className={styles.error_title} weight="bold" variant="h3">
        Something went wrong.
      </Text>
      <Text className={styles.error_description}>
        An unexpected error occurred. Please refresh and try again.
      </Text>
    </div>
  </>
);
