import React, { useEffect } from "react";
import cx from "classnames";
import usePortal from "../hooks/usePortal";
import styles from "./Modal.module.scss";

type Ref = HTMLDivElement;

interface ModalProps {
  className?: string;
  children: React.ReactNode;
}

const Modal = React.forwardRef<Ref, ModalProps>(
  ({ className, children }, ref) => {
    const ModalComponent = usePortal();

    useEffect(() => {
      document.body.classList.add(styles.preventScroll);

      return () => document.body.classList.remove(styles.preventScroll);
    }, []);

    const classes = cx(styles.modal, className);
    return (
      <ModalComponent>
        <div ref={ref} className={classes}>
          {children}
        </div>
      </ModalComponent>
    );
  }
);
export default Modal;
