import React from "react";
import cx from "classnames";
import { node, string, oneOf } from "prop-types";
import { useFormControlContext } from "../FormControl";
import styles from "./FormLabel.module.scss";

/**
 * Returns a label, that is used within a `FormControl` component
 * to provide a label for an input for accessibility.
 */

function FormLabel(props) {
  const { className, children, size, ...rest } = props;
  const groupContext = useFormControlContext();
  const labelProps = groupContext?.getLabelProps(rest) ?? { ...rest };

  const classNames = cx(
    styles.label,
    {
      [styles.xs]: props.size === "xs" ?? groupContext?.size === "xs",
    },
    className
  );

  return (
    <label className={classNames} {...labelProps}>
      {children}
      {groupContext?.isRequired ? (
        <span className={styles.required}>*</span>
      ) : null}
    </label>
  );
}

export default FormLabel;

FormLabel.propTypes = {
  /** The content of the label */
  children: node.isRequired,
  /** For CSS customisation */
  className: string,
  /** Change the font size of the label */
  size: oneOf(["md", "xs"]),
};

FormLabel.defaultProps = {
  size: "md",
};
