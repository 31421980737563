import React from "react";
import { MdCropFree, MdFullscreenExit, MdClose } from "react-icons/md";
import ActionButton from "../ReportControls/ActionButton/index";
import { useTranslation } from "react-i18next";
import styles from "./Recommendations.module.scss";

interface ActionsProps {
  onResize: () => void;
  onClose: () => void;
  isMaximized: boolean;
}

const Actions = ({ onResize, onClose, isMaximized }: ActionsProps) => {
  const { t } = useTranslation();
  const maximize = t("controls.window.maximize");
  const minimize = t("controls.window.minimize");
  const close = t("controls.window.close");
  
  return (<>
    <div className={styles.actions}>
      <ActionButton
        popperPlacement="bottom"
        className={styles.actions_maximize}
        label={isMaximized ? minimize : maximize}
        onClick={onResize}
      >
        {isMaximized ? <MdFullscreenExit /> : <MdCropFree />}
      </ActionButton>
      <ActionButton
        className={styles.actions_close}
        popperPlacement="bottom"
        label={close}
        onClick={onClose}
      >
        <MdClose />
      </ActionButton>
    </div>
    </>
    )
  };

export default Actions;
