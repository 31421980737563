import React from "react";
import styles from "./Dashboard.module.scss";
import Navigation from "../Navigation";

interface DashboardProps {
  children: React.ReactNode;
  isAdminView?: boolean;
}

/** Provides a wrapper for both client & admin dashboard for consistent styling */
function Dashboard({ children, isAdminView }: DashboardProps) {
  return (
    <div className={styles.dashboard}>
      <Navigation isAdminView={isAdminView} />
      {children}
    </div>
  );
}

export default Dashboard;
