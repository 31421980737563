import React from "react";
import cx from "classnames";
import Text from "components/generic/Text/Text";
import styles from "./Body.module.scss";

interface RankingProps {
  aligned?: "end";
  label: string;
  rank: string;
}

const Ranking = ({ aligned, label, rank }: RankingProps) => (
  <div
    className={cx(styles.body_ranking, {
      [styles.body_ranking_end]: aligned === "end",
    })}
  >
    <Text variant="overline" className={styles.body_ranking_overline}>
      {label}
    </Text>
    <Text
      variant="subheading"
      weight="bold"
      className={styles.body_ranking_heading}
    >
      {rank}
    </Text>
  </div>
);

const RankingGroup = ({ children }: { children: React.ReactNode }) => (
  <div className={styles.body_ranking_group}>{children}</div>
);

export default Ranking;

export { RankingGroup };
