import useOnClickOutside from "components/generic/hooks/useOnClickOutside";
import IconButton from "components/generic/IconButton/IconButton";
import Modal from "components/generic/Modal/Modal";
import Text from "components/generic/Text/Text";
import React, { useRef, useState } from "react";
import { MdClose, MdInfo } from "react-icons/md";
import styles from "./Recommendations.module.scss";

interface DisclaimerProps {
  isLoading: boolean;
}

const Disclaimer = ({ isLoading }: DisclaimerProps) => {
  const [isActive, setIsActive] = useState(true);
  const divRef = useRef(null);

  const handleHideDisclaimer = () => {
    if (!isActive) return false;

    setIsActive(false);

    return true;
  };

  useOnClickOutside(divRef, () => handleHideDisclaimer());

  if (!isActive || isLoading) return null;

  return (
    <Modal className={styles.disclaimer_modal}>
      <div ref={divRef} className={styles.disclaimer}>
        <IconButton
          className={styles.disclaimer_close}
          variant="ghost"
          size="md"
          label="Close disclaimer"
          onClick={() => handleHideDisclaimer()}
        >
          <MdClose />
        </IconButton>
        <div className={styles.disclaimer_body}>
          <div className={styles.disclaimer_header}>
            <MdInfo />
            <Text weight="bold" className={styles.disclaimer_title}>
              This is a limited experience
            </Text>
          </div>

          <Text className={styles.disclaimer_text}>
            You are currently viewing a limited report. Please view your report
            on a larger screen to access your full Digital Maturity Assessment.
          </Text>
        </div>
      </div>
    </Modal>
  );
};

export default Disclaimer;
