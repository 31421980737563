import React from "react";
import ReactDOM from "react-dom";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication, Configuration } from "@azure/msal-browser";
import App from "./App";
import "./styles/app.scss";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ClientDashboardTesting from "pages/ClientDashboardTesting";
import ErrorBoundary from "components/ErrorBoundary";
import LanguageSelector from "components/LanguageSelector/LanguageSelector";

const tenantId = process.env.REACT_APP_TENANT_ID ?? "";
const clientId = process.env.REACT_APP_CLIENT_ID ?? "";

const msalConfig: Configuration = {
  auth: {
    authority: `https://login.microsoftonline.com/${tenantId}`,
    clientId: `${clientId}`,
    redirectUri: "/",
  },
};

const pca = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route path="/testing" exact>
          <ErrorBoundary>
            <LanguageSelector />
            <ClientDashboardTesting />
          </ErrorBoundary>
        </Route>
        <MsalProvider instance={pca}>
          <App />
        </MsalProvider>
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
