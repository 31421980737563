import cx from "classnames";
import React from "react";
import ControlWrapper from "../ControlWrapper/ControlWrapper";
import styles from "./TaskButtons.module.scss";

type Ref = HTMLDivElement;

interface TaskButtonsProps {
  children: React.ReactNode;
  className?: string;
}

const TaskButtons = React.forwardRef<Ref, TaskButtonsProps>(
  ({ children, className }, ref) => (
    <div ref={ref} className={cx(styles.tasks, className)}>
      <ControlWrapper className={styles.tasks_wrapper}>
        {children}
      </ControlWrapper>
    </div>
  )
);

export default TaskButtons;
