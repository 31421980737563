import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import without from "lodash/without";
import some from "lodash/some";
import every from "lodash/every";
import groupBy from "lodash/groupBy";
import { VisualDescriptor } from "powerbi-client";
import { Dimensions, SubDimensions } from "types/dimensions";
import {
  Scores,
  BenchmarkData,
  DrilldownData,
  NewVisualDescriptor,
  PowerBiVisualTitles,
  VisualsToExport,
} from "../hooks/useReport";

/**
 * The titles of the Power BI visuals to be queried. If they are updated,
 * it needs to be reflected here too.
 */

export const VISUAL_TITLES: PowerBiVisualTitles[] = [
  "Answers",
  "Most common answers",
  "Benchmark scores",
  "Scores",
  "Total submissions",
];

export const DIMENSIONS: Dimensions[] = [
  "Sustainable Operating Model",
  "Culture",
  "Customer",
  "Data",
  "Capabilities",
  "Technology",
];

export const valueIsDimension = (value: any) => {
  return DIMENSIONS.includes(value) ? true : false;
};

const sustainableOperatingModelColours = [
  "rgba(0, 131, 143, 0.2)",
  "rgba(0, 131, 143, 1)",
];
const technologyColours = ["rgba(79, 45, 127, 0.2)", "rgba(79, 45, 127, 1)"];
const cultureColours = ["rgba(222, 0, 46, 0.2)", "rgba(222, 0, 46, 1)"];
const dataColours = ["rgba(203, 196, 188, 0.2)", "#B1A69A"];
const customerColours = ["rgba(155, 215, 50, 0.2)", "rgba(155, 215, 50, 1)"];
const capabilitiesColours = [
  "rgba(242, 107, 35, 0.2)",
  "rgba(242, 107, 35, 1)",
];

/**
 * Compare the pulled visuals to ensure they are matching
 * the above visual titles. If they aren't it should return an error.
 *
 * @param {array} visualsArray the array of visuals after using getVisuals
 */
export function pulledVisualsIsIncorrect(visualsArray: VisualDescriptor[]) {
  const visualTitlesFromArray = visualsArray
    .filter((v) => v.title !== undefined)
    .map((v) => v.title);

  return without(VISUAL_TITLES, ...visualTitlesFromArray).length > 0;
}

/**
 * For the benchmark data to be correct, it must have:
 *  - 6 unique dimensions
 *  - 4 unique sub-dimensions within each dimension
 *  - 24 items in the array
 */

type ReducedDataArray = {
  dimensions: Dimensions[];
  subDimensions: SubDimensions[];
};

export function checkBenchmarkData(dataArray: BenchmarkData[]) {
  if (!Array.isArray(dataArray) || dataArray?.length === 0) {
    return false;
  }

  if (dataArray.length !== 24) {
    return false;
  }

  const { dimensions, subDimensions } = dataArray.reduce(
    (acc: ReducedDataArray, curr) => ({
      dimensions: [...acc.dimensions, curr.dimension],
      subDimensions: [...acc.subDimensions, curr.qcode],
    }),
    {
      dimensions: [],
      subDimensions: [],
    }
  );

  const uniqueDimension = uniq(dimensions);

  if (uniqueDimension.length !== 6) {
    return false;
  }

  const uniqueSubDimensions = uniq(subDimensions);

  if (uniqueSubDimensions.length !== 24) {
    return false;
  }

  const groupedDimensions = groupBy(dataArray, "dimension");

  if (
    Object.keys(groupedDimensions).filter(
      (key) => groupedDimensions[key].length === 4
    ).length !== 6
  ) {
    return false;
  }

  return true;
}

/**
 * Checks parsed drilldown data array. To pass the check, the data array
 * must pass the following checks:
 *
 *  - Have unique questions
 *  - Have an average answer for each
 *  - Have 24 unique sub-dimensions
 */
export function checkDrilldownData(dataArray: DrilldownData[]) {
  if (!Array.isArray(dataArray) || dataArray?.length === 0) {
    return false;
  }

  const uniqueQuestions = uniqBy(dataArray, "question");

  if (uniqueQuestions.length !== 120) {
    return false;
  }

  if (
    some(dataArray, { averageAnswer: undefined }) &&
    !every(dataArray, { averageAnswer: undefined })
  ) {
    return false;
  }

  const groupedQuestions = groupBy(dataArray, "qcode");
  const questionSubDimensions = Object.keys(groupedQuestions);

  if (questionSubDimensions.length !== 24) {
    return false;
  }

  const correctSubDimensionQuestionAmount = every(
    questionSubDimensions.filter((key) => groupedQuestions[key].length === 5)
  );

  if (!correctSubDimensionQuestionAmount) {
    return false;
  }

  return true;
}

/**
 * To pass the check, the data passed in must pass the following checks:
 *  - It must have 24 objects for every survey code
 *  - For every 24 objects, there must be 6 unique dimensions
 *
 */
export function checkSubDimensionsData(dataArray: Scores[]) {
  if (!Array.isArray(dataArray) || dataArray?.length === 0) {
    return false;
  }

  const groupedUniqueSurveyCode = groupBy(dataArray, "surveyCode");
  const groupedDataKeys = Object.keys(groupedUniqueSurveyCode);

  if (dataArray.length !== groupedDataKeys.length * 24) {
    return false;
  }

  const correctDimensionsAndSubdimensionsPerSurveyCode = every(
    groupedDataKeys.map((key) => {
      let isTruthy = true;
      const surveyCodeArray = groupedUniqueSurveyCode[key];

      if (surveyCodeArray.length !== 24) isTruthy = false;

      if (uniqBy(surveyCodeArray, "dimension").length !== 6) isTruthy = false;

      if (uniqBy(surveyCodeArray, "qcode").length !== 24) isTruthy = false;

      return isTruthy;
    })
  );

  if (!correctDimensionsAndSubdimensionsPerSurveyCode) {
    return false;
  }

  return true;
}

/**
 * Reduces an array of visuals from Power BI into objects
 * that can be exported for their data.
 *
 * @param visuals The visuals to be reduced
 */

export function returnVisualsForExporting(visuals: NewVisualDescriptor[] = []) {
  return visuals.reduce(
    (acc: VisualsToExport, curr) => {
      if (curr.title === "Total submissions") {
        return {
          ...acc,
          totalSubmissions: curr,
        };
      }

      if (curr.title === "Most common answers") {
        return {
          ...acc,
          mostCommonAnswers: curr,
        };
      }

      if (curr.title === "Answers") {
        return {
          ...acc,
          clientAnswers: curr,
        };
      }

      if (curr.title === "Scores") {
        return {
          ...acc,
          subDimensionScores: curr,
        };
      }

      if (curr.title === "Benchmark scores") {
        return {
          ...acc,
          subDimensionBenchmarks: curr,
        };
      }
      return acc;
    },
    {
      clientAnswers: {},
      mostCommonAnswers: {},
      subDimensionBenchmarks: {},
      subDimensionScores: {},
      totalSubmissions: {},
    } as VisualsToExport
  );
}

/**
 * @returns color codes in the format [default, borderColor, hover]
 */
export function getDimensionColour(dimension: Dimensions) {
  switch (dimension) {
    case "Sustainable Operating Model":
      return sustainableOperatingModelColours;
    case "Technology":
      return technologyColours;
    case "Culture":
      return cultureColours;
    case "Data":
      return dataColours;
    case "Customer":
      return customerColours;
    case "Capabilities":
      return capabilitiesColours;
    default:
      return dimension;
  }
}

export function getMultipleColours() {
  return [sustainableOperatingModelColours, technologyColours, customerColours];
}
