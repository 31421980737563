import { Line } from "react-chartjs-2";
import styles from "./MostCommonAnswers.module.scss";
import useMostCommonAnswersGraph, {
  Dataset,
} from "./useMostCommonAnswersGraph";
import Graphs from "../Graphs";
import Text from "components/generic/Text/Text";
import { CurrentDrilldown, DrilldownData, SetLegend } from "hooks/useReport";
import { Dimensions } from "types/dimensions";
import { LineOptions } from "./options";

interface MostCommonAnswersProps {
  setLegend: SetLegend;
  activeTab: Dimensions;
  currentDrilldown: CurrentDrilldown;
  answerData: DrilldownData[];
}

function MostCommonAnswers({
  setLegend,
  activeTab,
  currentDrilldown,
  answerData,
}: MostCommonAnswersProps) {
  const [results, options] = useMostCommonAnswersGraph({
    activeTab,
    data: answerData,
    currentDrilldown,
    setLegend,
  });

  if (currentDrilldown === null) return null;

  return (
    <Graphs>
      <div className={styles.graph}>
        <div className={styles.graph_wrapper}>
          <div className={styles.graph_bars}>
            {results?.map((r) => (
              <BarChart key={r.question} {...r} options={options} />
            ))}
          </div>
        </div>
      </div>
    </Graphs>
  );
}

export default MostCommonAnswers;

interface BarChartProps {
  question: string;
  datasets: Dataset[];
  options: LineOptions;
}

const BarChart = ({ question, datasets, options }: BarChartProps) => {
  const questionId = question.slice(0, 5);
  const questionTitle = question.slice(6);

  return (
    <div className={styles.bar}>
      <div className={styles.bar_question}>
        <Text className={styles.bar_question_id} variant="caption">
          {questionId}
        </Text>
        <Text className={styles.bar_question_title}>{questionTitle}</Text>
      </div>
      <div className={styles.bar_chart}>
        <Line
          options={options}
          data={{
            datasets: [...datasets],
            labels: [question],
          }}
        />
      </div>
    </div>
  );
};
