import React from "react";
import styles from "./Forbidden.module.scss";
import Text from "components/generic/Text/Text";
import Link from "components/generic/Link/index";
import { Link as RouterLink } from "react-router-dom";

function Forbidden() {
  return (
    <section className={styles.error}>
      <div className={styles.error_wrapper}>
        <div className={styles.error_text}>
          <Text
            variant="subheading"
            weight="bold"
            className={styles.error_code}
          >
            Access denied
          </Text>
          <Text className={styles.error_message}>
            Access to this page is forbidden.
          </Text>
        </div>
        <div className={styles.error_return}>
          <Link component={RouterLink} to="/">
            Go back
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Forbidden;
