import Dashboard from "components/Dashboard";
import AdminResults from "pages/AdminResults";
import React from "react";
import { Route, useRouteMatch } from "react-router-dom";
import AdminLaunchpad from "./../../pages/AdminLaunchpad";
import useAdminDashboard, { AdminDashboardProps } from "./useAdminDashboard";

/**
 * Wrapper for Admin routes to manage state
 */
function AdminDashboard({ accessToken }: AdminDashboardProps) {
  const { path } = useRouteMatch();
  const { store, handlers } = useAdminDashboard();
  return (
    <>
      <Route path={`${path}`} exact>
        <Dashboard isAdminView>
          <AdminLaunchpad
            clients={store.clients}
            getAvailableClients={handlers.getAvailableClients}
            accessToken={accessToken}
            isLoading={store.isLoading}
          />
        </Dashboard>
      </Route>
      <Route path={`${path}/results/:clientEmail`} exact>
        <Dashboard isAdminView>
          <AdminResults
            saveReportState={handlers.saveReportState}
            savedReportStates={store.savedStates}
            accessToken={accessToken}
          />
        </Dashboard>
      </Route>
    </>
  );
}

export default AdminDashboard;
