import Icon from "assets/icons/Icon";
import Text from "components/generic/Text/Text";
import { getDimensionTranslationKey, TabsType } from "hooks/useReport";
import cx from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./Recommendations.module.scss";

interface TabsProps {
  tab: TabsType;
  setTab: (newTab: TabsType) => void;
}

function Tabs({ tab, setTab }: TabsProps) {
  const { t } = useTranslation();

  return (
    <div className={styles.tabs}>
      <div className={styles.tabs_inner}>
        <div className={styles.tabs_list}>
          <button
            type="button"
            onClick={() => setTab("Overview")}
            className={cx(styles.tabs_button, {
              [styles.active]: tab === "Overview",
            })}
          >
            <Text variant="caption">Home</Text>
          </button>
          {tab !== "Overview" && (
            <>
              <Icon
                className={styles.tabs_splitter}
                icon="GtChevronSmallRight"
              />
              <button
                type="button"
                className={cx(styles.tabs_button, styles.active)}
              >
                <Text variant="caption">
                  {t(`dimensions.${getDimensionTranslationKey(tab)}`)}
                </Text>
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Tabs;
