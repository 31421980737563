import { useTranslation, TFunction } from "react-i18next";
import { Dimensions } from "types/dimensions";
import {
  BenchmarkData,
  Scores,
  Ranks,
  reduceBenchmarksToDimensionBenchmarks,
} from "./useReport";

const getLimitForRank = () => {
  const nascentLimit = 20;
  const developingLimit = 40;
  const maturingLimit = 60;

  return [nascentLimit, developingLimit, maturingLimit];
};

export function getLabelRank(score: number): Ranks {
  const [nascentLimit, developingLimit, maturingLimit] = getLimitForRank();

  if (score < nascentLimit) return "Nascent";

  if (score >= nascentLimit && score < developingLimit) return "Developing";

  if (score >= developingLimit && score < maturingLimit) return "Maturing";

  return "Leading";
}

function generateRecommendations(
  dimension: Dimensions,
  dimensionScore: number,
  t: TFunction<"translation">
) {
  const rank = getLabelRank(dimensionScore);

  if (rank === "Leading") {
    return {
      suggestions: [],
      roadmap: [],
    };
  }

  return t(`recommendations.${rank}.${dimension}`);
}

interface UseRecommendationsProps {
  dimensionScores: Omit<Scores, "qcode" | "surveyCode">[];
  benchmarks: BenchmarkData[];
}

export type ScoresWithRecommendations = {
  benchmark: number;
  dimension: Dimensions;
  score: number;
  clientRecommendations: {
    suggestions: string[];
    roadmap: string[];
  };
};

/**
 * Hook for managing recommendations for the client.
 * @param {Map} scoreMap the map that contains the survey code & scores
 */
export default function useRecommendations({
  dimensionScores,
  benchmarks,
}: UseRecommendationsProps) {
  const { t } = useTranslation();

  const dimensionBenchmarks = reduceBenchmarksToDimensionBenchmarks(benchmarks);

  const scoresWithRecommendations =
    dimensionScores?.map<ScoresWithRecommendations>((score) => ({
      ...score,
      benchmark: dimensionBenchmarks.find(
        (benchmark) => benchmark.dimension === score.dimension
      )!.score,
      clientRecommendations: generateRecommendations(
        score.dimension,
        score.score,
        t
      ),
    }));

  const totalScore = scoresWithRecommendations?.reduce((acc, curr) => {
    return acc + curr.score;
  }, 0);

  return { scoresWithRecommendations, totalScore };
}
