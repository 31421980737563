import { useEffect, useState } from "react";

type Query =
  | "(orientation:landscape)"
  | "(min-width: 1025px)"
  | "(min-width: 769px) and (max-width: 1024px)"
  | "(max-width: 768px)";
/**
 * Custom hook that tells you whether a given media query is active.
 */
export default function useMediaQuery(query: Query) {
  const [matches, setMatches] = useState(false);
  useEffect(
    () => {
      const mediaQuery = window.matchMedia(query);
      setMatches(mediaQuery.matches);
      const handler = (event: MediaQueryListEvent) => setMatches(event.matches);
      mediaQuery.addEventListener("change", handler);
      return () => mediaQuery.removeEventListener("change", handler);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [] // Empty array ensures effect is only run on mount and unmount
  );
  return matches;
}
