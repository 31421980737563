import React, { useRef } from "react";
import {
  CurrentDrilldown,
  SetActiveTab,
  SetDrilldown,
  TabsType,
  ToggleDrilldown,
} from "hooks/useReport";
import useOnClickOutside from "components/generic/hooks/useOnClickOutside";
import DimensionsBar from "./DimensionsBar";
import DimensionDrilldown from "./DimensionsDrilldown";
import styles from "./NavigationBar.module.scss";

interface NavigationBarProps {
  activeTab: TabsType;
  setActiveTab: SetActiveTab;
  toggleDrilldown: ToggleDrilldown;
  currentDrilldown: CurrentDrilldown;
  setDrilldown: SetDrilldown;
  drilldownIsActive: boolean;
  isLoading: boolean;
}

function NavigationBar({
  activeTab,
  setActiveTab,
  toggleDrilldown,
  currentDrilldown,
  setDrilldown,
  drilldownIsActive,
  isLoading,
}: NavigationBarProps) {
  const navigationRef = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(navigationRef, () => {
    if (!drilldownIsActive) return;

    toggleDrilldown();
  });

  return (
    <div ref={navigationRef} className={styles.navigation}>
      <DimensionsBar
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        drilldownIsActive={drilldownIsActive}
        toggleDrilldown={toggleDrilldown}
        isLoading={isLoading}
      />
      {drilldownIsActive && (
        <DimensionDrilldown
          currentDrilldown={currentDrilldown}
          setDrilldown={setDrilldown}
          activeTab={activeTab}
        />
      )}
    </div>
  );
}

export default NavigationBar;
