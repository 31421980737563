import React from "react";
import cx from "classnames";
import styles from "./Text.module.scss";

interface TextProps {
  as?: React.ElementType;
  children: React.ReactNode;
  /** Choose the variant to be used */
  variant?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "subheading"
    | "body"
    | "bodyLarge"
    | "button"
    | "overline"
    | "caption";
  weight?: "bold" | "medium" | "regular";
  decoration?: "underline" | "none";
  align?: "inherit" | "left" | "center" | "right" | "justify";
  color?: "purple" | "black";
  className?: string;
}

const tagNames: Record<string, React.ElementType> = {
  bodyLarge: "p",
  body: "p",
  button: "p",
  buttonSmall: "p",
  caption: "span",
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  overline: "span",
  subheading: "h4",
};

/**
 * Returns a component that renders paragraphs and headings based on props. By default, it renders a `<p>` tag.
 */
const Text = React.forwardRef<React.ElementType, TextProps>(
  (
    {
      as,
      children,
      variant = "body",
      weight,
      decoration,
      align,
      color,
      className,
      ...rest
    },
    ref
  ) => {
    const classes = cx(
      styles.classes,
      {
        [styles.bodyLarge]: variant === "bodyLarge",
        [styles.bodyRegular]: variant === "body",
        [styles.caption]: variant === "caption",
        [styles.h1]: variant === "h1",
        [styles.h2]: variant === "h2",
        [styles.h3]: variant === "h3",
        [styles.h4]: variant === "h4",
        [styles.overline]: variant === "overline",
        [styles.subheading]: variant === "subheading",
        [styles.bold]: weight === "bold",
        [styles.medium]: weight === "medium",
        [styles.regular]: weight === "regular",
        [styles.underline]: decoration === "underline",
        [styles.none]: decoration === "none",
        [styles.alignInherit]: align === "inherit",
        [styles.alignLeft]: align === "left",
        [styles.alignCenter]: align === "center",
        [styles.alignRight]: align === "right",
        [styles.alignJustify]: align === "justify",
        [styles.purple]: color === "purple",
        [styles.black]: color === "black",
      },
      className
    );

    // Defaults to `bodyRegular`
    const Element = as ?? tagNames["body"];

    return (
      <Element ref={ref} className={classes} {...rest}>
        {children}
      </Element>
    );
  }
);

export default Text;
