import React from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import ErrorComponent from "./ErrorComponent";

const ErrorBoundary = ({ children }: { children: React.ReactNode }) => (
  <ReactErrorBoundary FallbackComponent={ErrorComponent}>
    {children}
  </ReactErrorBoundary>
);

export default ErrorBoundary;
