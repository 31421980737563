import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationsEN from "./en/translations.json";
import translationsJA from "./ja/translations.json";

export type LanguageLabels = "English" | "Japanese";
export type LanguageCodes = "en" | "ja";

export const resources = {
  en: {
    translation: translationsEN,
  },
  ja: {
    translation: translationsJA,
  },
} as const;

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "en",
    cache: {
      enabled: true,
    },
    defaultNS: "translation",
    debug: process.env.NODE_ENV === "development",
    fallbackLng: {
      "en-GB": ["en"],
      "en-US": ["en"],
      default: ["en"],
    },
    react: {
      useSuspense: false,
    },
    returnObjects: true,
    resources,
  });
export default i18n;
