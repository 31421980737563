import { BenchmarkData, Scores, SetLegend, TabsType } from "hooks/useReport";
import { Radar } from "react-chartjs-2";
import Graphs from "../Graphs";
import styles from "./DimensionsGraph.module.scss";
import useDimensionsGraph from "./useDimensionsGraph";

interface DimensionsGraphProps {
  scores: Scores[];
  benchmarks: BenchmarkData[];
  activeTab: TabsType;
  benchmarkActive: boolean;
  surveyCodes: string[];
  setLegend: SetLegend;
  isMulti?: boolean;
}

function DimensionsGraph({
  scores,
  benchmarks,
  activeTab,
  benchmarkActive,
  surveyCodes,
  setLegend,
  isMulti,
}: DimensionsGraphProps) {
  const [data, options] = useDimensionsGraph({
    activeTab,
    benchmarkActive,
    benchmarks,
    scores,
    isMulti,
    setLegend,
    surveyCodes,
  });

  if (data.datasets.length === 0) return null;

  return (
    <Graphs>
      <div className={styles.graph}>
        <div className={styles.graph_wrapper}>
          <Radar options={options} data={data} />
        </div>
      </div>
    </Graphs>
  );
}

export default DimensionsGraph;
