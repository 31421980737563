import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import ControlWrapper from "../ControlWrapper/ControlWrapper";
import styles from "./Dropdown.module.scss";

interface DropdownProps {
  children: React.ReactNode;
  className?: string;
}

export default function Dropdown({ children, className }: DropdownProps) {
  return (
    <ControlWrapper className={cx(styles.dropdown, className)}>
      {children}
    </ControlWrapper>
  );
}

Dropdown.propTypes = {
  children: PropTypes.node.isRequired,
};
