import React from "react";
import cx from "classnames";
import Icon from "assets/icons/Icon";
import styles from "./Body.module.scss";
import getDimensionIcon from "utils/getDimensionIcon";
import { Dimensions } from "types/dimensions";

interface ColouredIconProps {
  dimension: Dimensions;
  isReversed?: boolean;
}

const ColouredIcon = ({ dimension, isReversed }: ColouredIconProps) => {
  const icon = getDimensionIcon(dimension);
  return (
    <div
      className={cx(styles.icon, {
        [styles.teal]: dimension === "Sustainable Operating Model",
        [styles.orange]: dimension === "Capabilities",
        [styles.purple]: dimension === "Technology",
        [styles.green]: dimension === "Customer",
        [styles.red]: dimension === "Culture",
        [styles.softGray]: dimension === "Data",
        [styles.reversed]: isReversed,
      })}
    >
      <Icon icon={icon} />
    </div>
  );
};

export default ColouredIcon;
